import { SnippetsOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Row, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import DatePickerFilter from "../../components/DatePickerFilter";
import Title from "../../components/Title";
import { HEADERS, RESOURCES } from '../../constants';

interface DataPrevision {
    centro: string;
    colada: number;
    TN: number;
    TN_percentage: number;
    KW: number;
}

interface DataType {
    key: React.Key;
    fecha: string;
    data: DataPrevision[];
}

function GetBackgroundColorBasedOnPercentage(value: number) {
    if (value < 25) return '#ffa2a2'
    else if (value < 50) return '#ffffb0'
    else return '#bfffbe'
}

const columns: ColumnsType<DataType> = [
    {
        title: 'Fecha',
        key: 'fecha',
        dataIndex: 'fecha',
        width: 120
    },
    {
        title: 'Previsión CC1',
        children: [
            {
                title: 'SMS',
                children: [
                    {
                        title: 'TN',
                        key: 'data_SMS_TN',
                        dataIndex: 'data',
                        width: 160,
                        render: (data: DataPrevision[]) => {
                            if (data == null) return (<div></div>)
                            let idx = data.findIndex(f => f.centro === "SMS" && f.colada == 1);
                            if (idx < 0) return (<div></div>)
                            return (
                                <div>
                                    <div style={{ zIndex: 0, position: 'absolute', margin: '-20px 0 0 -10px', width: data[idx].TN_percentage * 160 / 100, height: 40, background: GetBackgroundColorBasedOnPercentage(data[idx].TN_percentage) }}>
                                    </div>
                                    <div style={{ zIndex: 1, position: 'absolute', textAlign: 'center', width: 160, height: 40, marginTop: -10 }}>{data[idx].TN}  ({data[idx].TN_percentage}%)</div>
                                </div>
                            )
                        }
                    },
                    {
                        title: RESOURCES.KW,
                        key: 'data_SMS_KW',
                        dataIndex: 'data',
                        render: (data: DataPrevision[]) => {
                            if (data == null) return (<div></div>)
                            let idx = data.findIndex(f => f.centro === "SMS" && f.colada == 1);
                            if (idx < 0) return (<div></div>)
                            return (
                                <div> {data[idx].KW}  </div>
                            )
                        }
                    }
                ]
            },
            {
                title: 'Pomini',
                children: [
                    {
                        title: 'TN',
                        key: 'data_PLE_TN',
                        dataIndex: 'data',
                        width: 160,
                        render: (data: DataPrevision[]) => {
                            if (data == null) return (<div></div>)
                            let idx = data.findIndex(f => f.centro === "Pletinas" && f.colada == 1);
                            if (idx < 0) return (<div></div>)
                            return (
                                <div>
                                    <div style={{ zIndex: 0, position: 'absolute', margin: '-20px 0 0 -10px', width: data[idx].TN_percentage * 160 / 100, height: 40, background: GetBackgroundColorBasedOnPercentage(data[idx].TN_percentage) }}>
                                    </div>
                                    <div style={{ zIndex: 1, position: 'absolute', textAlign: 'center', width: 160, height: 40, marginTop: -10 }}>{data[idx].TN}  ({data[idx].TN_percentage}%)</div>
                                </div>
                            )
                        }
                    },
                    {
                        title: RESOURCES.KW,
                        key: 'data_PLE_KW',
                        dataIndex: 'data',
                        render: (data: DataPrevision[]) => {
                            if (data == null) return (<div></div>)
                            let idx = data.findIndex(f => f.centro === "Pletinas" && f.colada == 1);
                            if (idx < 0) return (<div></div>)
                            return (
                                <div> {data[idx].KW}  </div>
                            )
                        }
                    }
                ]
            }
        ]
    },
    {
        title: 'Previsión CC2',
        children: [
            {
                title: 'Danieli',
                children: [
                    {
                        title: 'TN',
                        key: 'data_DAN_TN',
                        dataIndex: 'data',
                        width: 160,
                        render: (data: DataPrevision[]) => {
                            if (data == null) return (<div></div>)
                            let idx = data.findIndex(f => f.centro === "Danieli" && f.colada == 2);
                            if (idx < 0) return (<div></div>)
                            return (
                                <div>
                                    <div style={{ zIndex: 0, position: 'absolute', margin: '-20px 0 0 -10px', width: data[idx].TN_percentage * 160 / 100, height: 40, background: GetBackgroundColorBasedOnPercentage(data[idx].TN_percentage) }}>
                                    </div>
                                    <div style={{ zIndex: 1, position: 'absolute', textAlign: 'center', width: 160, height: 40, marginTop: -10 }}>{data[idx].TN}  ({data[idx].TN_percentage}%)</div>
                                </div>
                            )
                        }
                    },
                    {
                        title: RESOURCES.KW,
                        key: 'data_DAN_KW',
                        dataIndex: 'data',
                        render: (data: DataPrevision[]) => {
                            if (data == null) return (<div></div>)
                            let idx = data.findIndex(f => f.centro === "Danieli" && f.colada == 2);
                            if (idx < 0) return (<div></div>)
                            return (
                                <div> {data[idx].KW}  </div>
                            )
                        }
                    }
                ]
            },
            {
                title: 'Pomini',
                children: [
                    {
                        title: 'TN',
                        key: 'data_PLE_TN',
                        dataIndex: 'data',
                        width: 160,
                        render: (data: DataPrevision[]) => {
                            if (data == null) return (<div></div>)
                            let idx = data.findIndex(f => f.centro === "Pletinas" && f.colada == 2);
                            if (idx < 0) return (<div></div>)
                            return (
                                <div>
                                    <div style={{ zIndex: 0, position: 'absolute', margin: '-20px 0 0 -10px', width: data[idx].TN_percentage * 160 / 100, height: 40, background: GetBackgroundColorBasedOnPercentage(data[idx].TN_percentage) }}>
                                    </div>
                                    <div style={{ zIndex: 1, position: 'absolute', textAlign: 'center', width: 160, height: 40, marginTop: -10 }}>{data[idx].TN}  ({data[idx].TN_percentage}%)</div>
                                </div>
                            )
                        }
                    },
                    {
                        title: RESOURCES.KW,
                        key: 'data_PLE_KW',
                        dataIndex: 'data',
                        render: (data: DataPrevision[]) => {
                            if (data == null) return (<div></div>)
                            let idx = data.findIndex(f => f.centro === "Pletinas" && f.colada == 2);
                            if (idx < 0) return (<div></div>)
                            return (
                                <div> {data[idx].KW}  </div>
                            )
                        }
                    }
                ]
            }
        ]
    }
];

const data: DataType[] = [
    {
        key: '1',
        fecha: '21/4/2023',
        data: [
            {
                centro: 'SMS',
                colada: 1,
                TN_percentage: 95,
                KW: Math.floor(20 + Math.random() * (200 - 20)),
                TN: Math.floor(20 + Math.random() * (2000 - 20))
            },
            {
                centro: 'Pletinas',
                colada: 1,
                TN_percentage: 55,
                KW: Math.floor(20 + Math.random() * (200 - 20)),
                TN: Math.floor(20 + Math.random() * (2000 - 20))
            },
            {
                centro: 'Danieli',
                colada: 2,
                TN_percentage: 25,
                KW: Math.floor(20 + Math.random() * (200 - 20)),
                TN: Math.floor(20 + Math.random() * (2000 - 20))
            },
            {
                centro: 'Pletinas',
                colada: 2,
                TN_percentage: 65,
                KW: Math.floor(20 + Math.random() * (200 - 20)),
                TN: Math.floor(20 + Math.random() * (2000 - 20))
            }
        ] as DataPrevision[]
    },
    {
        key: '2',
        fecha: '22/4/2023',
        data: [
            {
                centro: 'SMS',
                colada: 1,
                TN_percentage: 25,
                KW: Math.floor(20 + Math.random() * (200 - 20)),
                TN: Math.floor(20 + Math.random() * (2000 - 20))
            },
            {
                centro: 'Pletinas',
                colada: 1,
                TN_percentage: 2,
                KW: Math.floor(20 + Math.random() * (200 - 20)),
                TN: Math.floor(20 + Math.random() * (2000 - 20))
            },
            {
                centro: 'Danieli',
                colada: 2,
                TN_percentage: 25,
                KW: Math.floor(20 + Math.random() * (200 - 20)),
                TN: Math.floor(20 + Math.random() * (2000 - 20))
            },
            {
                centro: 'Pletinas',
                colada: 2,
                TN_percentage: 5,
                KW: Math.floor(20 + Math.random() * (200 - 20)),
                TN: Math.floor(20 + Math.random() * (2000 - 20))
            }
        ] as DataPrevision[]
    },
    {
        key: '3',
        fecha: '23/4/2023',
        data: [
            {
                centro: 'SMS',
                colada: 1,
                TN_percentage: 85,
                KW: Math.floor(20 + Math.random() * (200 - 20)),
                TN: Math.floor(20 + Math.random() * (2000 - 20))
            },
            {
                centro: 'Pletinas',
                colada: 1,
                TN_percentage: 70,
                KW: Math.floor(20 + Math.random() * (200 - 20)),
                TN: Math.floor(20 + Math.random() * (2000 - 20))
            },
            {
                centro: 'Danieli',
                colada: 2,
                TN_percentage: 75,
                KW: Math.floor(20 + Math.random() * (200 - 20)),
                TN: Math.floor(20 + Math.random() * (2000 - 20))
            },
            {
                centro: 'Pletinas',
                colada: 2,
                TN_percentage: 65,
                KW: Math.floor(20 + Math.random() * (200 - 20)),
                TN: Math.floor(20 + Math.random() * (2000 - 20))
            }
        ] as DataPrevision[]
    },
    {
        key: '4',
        fecha: '24/4/2023',
        data: [
            {
                centro: 'SMS',
                colada: 1,
                TN_percentage: 55,
                KW: Math.floor(20 + Math.random() * (200 - 20)),
                TN: Math.floor(20 + Math.random() * (2000 - 20))
            },
            {
                centro: 'Pletinas',
                colada: 1,
                TN_percentage: 75,
                KW: Math.floor(20 + Math.random() * (200 - 20)),
                TN: Math.floor(20 + Math.random() * (2000 - 20))
            },
            {
                centro: 'Danieli',
                colada: 2,
                TN_percentage: 51,
                KW: Math.floor(20 + Math.random() * (200 - 20)),
                TN: Math.floor(20 + Math.random() * (2000 - 20))
            },
            {
                centro: 'Pletinas',
                colada: 2,
                TN_percentage: 25,
                KW: Math.floor(20 + Math.random() * (200 - 20)),
                TN: Math.floor(20 + Math.random() * (2000 - 20))
            }
        ] as DataPrevision[]
    },
    {
        key: '5',
        fecha: '25/4/2023',
        data: [
            {
                centro: 'SMS',
                colada: 1,
                TN_percentage: 47,
                KW: Math.floor(20 + Math.random() * (200 - 20)),
                TN: Math.floor(20 + Math.random() * (2000 - 20))
            },
            {
                centro: 'Pletinas',
                colada: 1,
                TN_percentage: 45,
                KW: Math.floor(20 + Math.random() * (200 - 20)),
                TN: Math.floor(20 + Math.random() * (2000 - 20))
            },
            {
                centro: 'Danieli',
                colada: 2,
                TN_percentage: 41,
                KW: Math.floor(20 + Math.random() * (200 - 20)),
                TN: Math.floor(20 + Math.random() * (2000 - 20))
            },
            {
                centro: 'Pletinas',
                colada: 2,
                TN_percentage: 35,
                KW: Math.floor(20 + Math.random() * (200 - 20)),
                TN: Math.floor(20 + Math.random() * (2000 - 20))
            }
        ] as DataPrevision[]
    },
    {
        key: '6',
        fecha: '26/4/2023',
        data: [
            {
                centro: 'SMS',
                colada: 1,
                TN_percentage: 28,
                KW: Math.floor(20 + Math.random() * (200 - 20)),
                TN: Math.floor(20 + Math.random() * (2000 - 20))
            },
            {
                centro: 'Pletinas',
                colada: 1,
                TN_percentage: 13,
                KW: Math.floor(20 + Math.random() * (200 - 20)),
                TN: Math.floor(20 + Math.random() * (2000 - 20))
            },
            {
                centro: 'Danieli',
                colada: 2,
                TN_percentage: 68,
                KW: Math.floor(20 + Math.random() * (200 - 20)),
                TN: Math.floor(20 + Math.random() * (2000 - 20))
            },
            {
                centro: 'Pletinas',
                colada: 2,
                TN_percentage: 51,
                KW: Math.floor(20 + Math.random() * (200 - 20)),
                TN: Math.floor(20 + Math.random() * (2000 - 20))
            }
        ] as DataPrevision[]
    },
    {
        key: '7',
        fecha: '27/4/2023',
        data: [
            {
                centro: 'SMS',
                colada: 1,
                TN_percentage: 25,
                KW: Math.floor(20 + Math.random() * (200 - 20)),
                TN: Math.floor(20 + Math.random() * (2000 - 20))
            },
            {
                centro: 'Pletinas',
                colada: 1,
                TN_percentage: 50,
                KW: Math.floor(20 + Math.random() * (200 - 20)),
                TN: Math.floor(20 + Math.random() * (2000 - 20))
            },
            {
                centro: 'Danieli',
                colada: 2,
                TN_percentage: 49,
                KW: Math.floor(20 + Math.random() * (200 - 20)),
                TN: Math.floor(20 + Math.random() * (2000 - 20))
            },
            {
                centro: 'Pletinas',
                colada: 2,
                TN_percentage: 88,
                KW: Math.floor(20 + Math.random() * (200 - 20)),
                TN: Math.floor(20 + Math.random() * (2000 - 20))
            }
        ] as DataPrevision[]
    },
    {
        key: '8',
        fecha: '28/4/2023',
        data: [
            {
                centro: 'SMS',
                colada: 1,
                TN_percentage: 85,
                KW: Math.floor(20 + Math.random() * (200 - 20)),
                TN: Math.floor(20 + Math.random() * (2000 - 20))
            },
            {
                centro: 'Pletinas',
                colada: 1,
                TN_percentage: 75,
                KW: Math.floor(20 + Math.random() * (200 - 20)),
                TN: Math.floor(20 + Math.random() * (2000 - 20))
            },
            {
                centro: 'Danieli',
                colada: 2,
                TN_percentage: 65,
                KW: Math.floor(20 + Math.random() * (200 - 20)),
                TN: Math.floor(20 + Math.random() * (2000 - 20))
            },
            {
                centro: 'Pletinas',
                colada: 2,
                TN_percentage: 53,
                KW: Math.floor(20 + Math.random() * (200 - 20)),
                TN: Math.floor(20 + Math.random() * (2000 - 20))
            }
        ] as DataPrevision[]
    },
    {
        key: '9',
        fecha: '29/4/2023',
        data: [
            {
                centro: 'SMS',
                colada: 1,
                TN_percentage: 25,
                KW: Math.floor(20 + Math.random() * (200 - 20)),
                TN: Math.floor(20 + Math.random() * (2000 - 20))
            },
            {
                centro: 'Pletinas',
                colada: 1,
                TN_percentage: 25,
                KW: Math.floor(20 + Math.random() * (200 - 20)),
                TN: Math.floor(20 + Math.random() * (2000 - 20))
            },
            {
                centro: 'Danieli',
                colada: 2,
                TN_percentage: 25,
                KW: Math.floor(20 + Math.random() * (200 - 20)),
                TN: Math.floor(20 + Math.random() * (2000 - 20))
            },
            {
                centro: 'Pletinas',
                colada: 2,
                TN_percentage: 25,
                KW: Math.floor(20 + Math.random() * (200 - 20)),
                TN: Math.floor(20 + Math.random() * (2000 - 20))
            }
        ] as DataPrevision[]
    },
    {
        key: '10',
        fecha: '30/4/2023',
        data: [
            {
                centro: 'SMS',
                colada: 1,
                TN_percentage: 25,
                KW: Math.floor(20 + Math.random() * (200 - 20)),
                TN: Math.floor(20 + Math.random() * (2000 - 20))
            },
            {
                centro: 'Pletinas',
                colada: 1,
                TN_percentage: 25,
                KW: Math.floor(20 + Math.random() * (200 - 20)),
                TN: Math.floor(20 + Math.random() * (2000 - 20))
            },
            {
                centro: 'Danieli',
                colada: 2,
                TN_percentage: 25,
                KW: Math.floor(20 + Math.random() * (200 - 20)),
                TN: Math.floor(20 + Math.random() * (2000 - 20))
            },
            {
                centro: 'Pletinas',
                colada: 2,
                TN_percentage: 25,
                KW: Math.floor(20 + Math.random() * (200 - 20)),
                TN: Math.floor(20 + Math.random() * (2000 - 20))
            }
        ] as DataPrevision[]
    },
    {
        key: '11',
        fecha: '01/5/2023',
        data: [
            {
                centro: 'SMS',
                colada: 1,
                TN_percentage: 25,
                KW: Math.floor(20 + Math.random() * (200 - 20)),
                TN: Math.floor(20 + Math.random() * (2000 - 20))
            },
            {
                centro: 'Pletinas',
                colada: 1,
                TN_percentage: 25,
                KW: Math.floor(20 + Math.random() * (200 - 20)),
                TN: Math.floor(20 + Math.random() * (2000 - 20))
            },
            {
                centro: 'Danieli',
                colada: 2,
                TN_percentage: 25,
                KW: Math.floor(20 + Math.random() * (200 - 20)),
                TN: Math.floor(20 + Math.random() * (2000 - 20))
            },
            {
                centro: 'Pletinas',
                colada: 2,
                TN_percentage: 25,
                KW: Math.floor(20 + Math.random() * (200 - 20)),
                TN: Math.floor(20 + Math.random() * (2000 - 20))
            }
        ] as DataPrevision[]
    }
];


const PrevisionCCYConsumoElectrico = () => {
    return (
        <div style={{ padding: '16px 16px' }}>
            <Title title={HEADERS.CC_ELECTRIC_CONSUMPTION_FORECAST} />
            <Row>
                <Col span={20}>
                    <Button type="primary" ><SnippetsOutlined />Gestionar planes</Button>
                </Col>
                <Col span={4}>
                    <DatePickerFilter />
                </Col>
            </Row>
            <Divider />
            <Table size='small' bordered dataSource={data} columns={columns} />
        </div>
    )
}

export default PrevisionCCYConsumoElectrico;