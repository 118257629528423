import { Modal } from 'antd'
import { AuthService } from 'aquiles-security-library'
import axios from 'axios'
import { SETTINGS } from '../constants'

export interface HeaderRequestModel {
  Accept?: string
  'Content-Type': string
  Authorization?: string
  authRequired?: boolean
}

const getHeaders = (options: any) => {
  const token = localStorage.getItem('srg_token')
  let headers = {
    'Content-Type': options['Content-Type'] ? options['Content-Type'] : 'application/json'
  } as HeaderRequestModel
  if (token) {
    headers['Authorization'] = `Bearer ${token}`
  }

  return {
    headers: { ...headers },
    responseType: options?.responseType,
    authRequired: options?.authRequired ? options.authRequired : true
  }
}

// INTERCEPTORS
axios.interceptors.request.use(
  async (config: any) => {
    const auth = new AuthService()
    config.headers['Authorization'] = `Bearer ${auth.getToken(SETTINGS.tokenName)}`
    if (!auth.isAuthenticated(SETTINGS.tokenName) && config.headers.authRequired) {
      // Access Token was expired
      auth.logout(SETTINGS.tokenName)

      if (window.location.pathname !== '/') {
        window.location.href = `${window.location.protocol}//${window.location.hostname}:${window.location.port}/`
      }

      return false
    }

    return config
  },
  (error) => Promise.reject(error)
)

axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const messageError = error?.response?.data?.Message || error.message
    const modal = Modal.error({})

    if (error.response?.status === 401) {
      modal.update({
        title: 'Acceso denegado',
        content: 'No tienes los permisos necesarios para realizar esta acción'
      })
    } else {
      modal.update({
        title: 'Error',
        content: messageError
      })
    }

    return Promise.reject(error)
  }
)

export const makeGetRequest = (url: string, options: {}) => {
  return axios.get(url, getHeaders(options)).then((response) => response.data)
  //.catch((error: any) => message.error(error.message, 10));
}

export const makeGetRequestWithHeaders = (url: string, options: {}) => {
  return axios.get(url, getHeaders(options)).then((response) => response)
  //.catch((error: any) => message.error(error.message, 10));
}
export const makePostRequest = (url: string, data: any, options: {}) => {
  return axios
    .post(url, data, getHeaders(options))
    .then((response) => response.data)
    .catch((error) => {
      console.error(error)
      throw error
    })
}

export const makePutRequest = (url: string, data: any, options: {}) => {
  return axios.put(url, data, getHeaders(options)).then((response) => response.data)
}

export const makeDeleteRequest = (url: string, data: any, options: {}) => {
  return axios.delete(url, { ...getHeaders(options), data }).then((response) => response.data)
}
