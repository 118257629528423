import { EditFilled, LikeFilled } from '@ant-design/icons';
import { Button, Card, Col, Row, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import Title from '../../../components/Title';
import { RESOURCES, HEADERS } from '../../../constants';

interface DataType {
    key: string;
    calidadDestino: CalidadDestino[];
}

interface CalidadDestino {
    calidad: string;
    warning: boolean;
    pasos: string[] | undefined;
}

function GetCalidadDestinos() {
    let array = ["355 PERF", "C-1018", "J2", "K-05", "K05-M", "K-06", "K-06 400", "K-06 T", "K-06 T1", "K-06 T2", "K-06 T3", "K15-3", "PG-1", "Rollo / Rollo-2",
        "S-355-JO", "SAE-1018", "ST-52.3", "T-10", "T-11", "T-12", "T-16", "T-20", "T-21", "T-22", "T-5", "T-6", "T-8B"];
    let lCalidades = [];

    for (let i = 0; i < array.length; i++) {
        lCalidades.push(
            {
                title: array[i],
                dataIndex: 'calidadDestino',
                width: 200,
                render: (calidadDestino: CalidadDestino[]) => {
                    if (calidadDestino.length > i)
                        if (calidadDestino[i].pasos == undefined)
                            return (<div style={{ height: 200, width: 200, padding: 0, margin: -8, background: '#00000044' }}></div>)
                        else if (calidadDestino[i].pasos?.length == 0)
                            return (
                                <Card size='small' style={{ height: 200, width: 200, background: 'rgb(227, 255, 226)', margin: -8 }}>
                                    <Row>
                                        <Col span={20}>
                                            <div style={{ marginTop: 65, marginLeft: 65 }}>
                                                <LikeFilled style={{ fontSize: 24, color: 'green' }} />
                                            </div>
                                        </Col>
                                        <Col span={4}>
                                            <Button style={{ background: '#eaffea', height: 30, width: 30, padding: 0, margin: 0 }}>
                                                <EditFilled></EditFilled>
                                            </Button>
                                        </Col>
                                    </Row>
                                </Card>
                            )
                        else {
                            let backColor = calidadDestino[i].warning ? '#ffc9c9' : '#FFFFFF' as string;
                            let buttonColor = calidadDestino[i].warning ? '#FF9999' : '#eaffea' as string;
                            return (
                                <Card size='small' style={{ height: 200, width: 200, background: backColor, margin: -8 }}>
                                    <Row>
                                        <Col span={20}>
                                            {
                                                calidadDestino[i].pasos?.map((paso) => {
                                                    return (
                                                        <Row> {paso} </Row>
                                                    )
                                                })
                                            }
                                        </Col>
                                        <Col span={4}>
                                            <Button style={{ background: buttonColor, height: 30, width: 30, padding: 0, margin: 0 }}>
                                                <EditFilled></EditFilled>
                                            </Button>
                                        </Col>
                                    </Row>
                                </Card>
                            )
                        }
                    else
                        return (<div style={{ background: '#00000055' }}><Row> </Row></div>)
                }
            }
        )
    }

    return lCalidades;
}

const data: DataType[] = [
    {
        key: '355 PERF',
        calidadDestino: [
            {
                calidad: '355 PERF',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'C-1018',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'J2',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-05',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-05-M',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 400',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T1',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T2',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-15-3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'PG-1',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'Rollo/Rollo-2',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'S-355-JO',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'SAE-1018',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'ST-52.3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-10',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-11',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-12',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-16',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-20',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-21',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-22',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-5',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-6',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-8B',
                pasos: undefined,
                warning: false
            }
        ]
    },
    {
        key: 'C-1018',
        calidadDestino: [
            {
                calidad: '355 PERF',
                pasos: ["ST-52.3", "T-15", "T-12"]
                ,
                warning: false
            },
            {
                calidad: 'C-1018',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'J2',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-05',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-05-M',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 400',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T1',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T2',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-15-3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'PG-1',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'Rollo/Rollo-2',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'S-355-JO',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'SAE-1018',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'ST-52.3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-10',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-11',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-12',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-16',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-20',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-21',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-22',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-5',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-6',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-8B',
                pasos: undefined,
                warning: false
            }
        ]
    },
    {
        key: 'J2',
        calidadDestino: [
            {
                calidad: '355 PERF',
                pasos: []
                ,
                warning: false
            },
            {
                calidad: 'C-1018',
                pasos: ["T-12", "T-15", "ST-52.3"]
                ,
                warning: false
            },
            {
                calidad: 'J2',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-05',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-05-M',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 400',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T1',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T2',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-15-3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'PG-1',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'Rollo/Rollo-2',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'S-355-JO',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'SAE-1018',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'ST-52.3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-10',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-11',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-12',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-16',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-20',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-21',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-22',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-5',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-6',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-8B',
                pasos: undefined,
                warning: false
            }
        ]
    },
    {
        key: 'K-05',
        calidadDestino: [
            {
                calidad: '355 PERF',
                pasos: ["ST-52.3", "T-15", "T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'C-1018',
                pasos: ["T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'J2',
                pasos: ["ST-52.3", "T-15", "T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'K-05',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-05-M',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 400',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T1',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T2',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-15-3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'PG-1',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'Rollo/Rollo-2',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'S-355-JO',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'SAE-1018',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'ST-52.3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-10',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-11',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-12',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-16',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-20',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-21',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-22',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-5',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-6',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-8B',
                pasos: undefined,
                warning: false
            }
        ]
    },
    {
        key: 'K05-M',
        calidadDestino: [
            {
                calidad: '355 PERF',
                pasos: ["ST-52.3", "T-15", "T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'C-1018',
                pasos: ["T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'J2',
                pasos: ["ST-52.3", "T-15", "T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'K-05',
                pasos: ["ST-52.3", "T-15", "T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'K-05-M',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 400',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T1',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T2',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-15-3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'PG-1',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'Rollo/Rollo-2',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'S-355-JO',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'SAE-1018',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'ST-52.3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-10',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-11',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-12',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-16',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-20',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-21',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-22',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-5',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-6',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-8B',
                pasos: undefined,
                warning: false
            }
        ]
    },
    {
        key: 'K-06',
        calidadDestino: [
            {
                calidad: '355 PERF',
                pasos: ["ST-52.3", "T-15", "T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'C-1018',
                pasos: ["T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'J2',
                pasos: ["ST-52.3", "T-15", "T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'K-05',
                pasos: ["ST-52.3", "T-15", "T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'K-05-M',
                pasos: ["ST-52.3", "T-15", "T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'K-06',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 400',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T1',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T2',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-15-3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'PG-1',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'Rollo/Rollo-2',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'S-355-JO',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'SAE-1018',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'ST-52.3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-10',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-11',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-12',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-16',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-20',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-21',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-22',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-5',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-6',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-8B',
                pasos: undefined,
                warning: false
            }
        ]
    },
    {
        key: 'K-06 400',
        calidadDestino: [
            {
                calidad: '355 PERF',
                pasos: ["ST-52.3", "T-15", "T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'C-1018',
                pasos: ["T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'J2',
                pasos: ["ST-52.3", "T-15", "T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'K-05',
                pasos: ["ST-52.3", "T-15", "T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'K-05-M',
                pasos: ["ST-52.3", "T-15", "T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'K-06',
                pasos: ["ST-52.3", "T-15", "T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'K-06 400',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T1',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T2',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-15-3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'PG-1',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'Rollo/Rollo-2',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'S-355-JO',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'SAE-1018',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'ST-52.3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-10',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-11',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-12',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-16',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-20',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-21',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-22',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-5',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-6',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-8B',
                pasos: undefined,
                warning: false
            }
        ]
    },
    {
        key: 'K-06 T',
        calidadDestino: [
            {
                calidad: '355 PERF',
                pasos: ["ST-52.3", "T-15", "T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: true
            },
            {
                calidad: 'C-1018',
                pasos: ["T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'J2',
                pasos: ["ST-52.3", "T-15", "T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'K-05',
                pasos: ["ST-52.3", "T-15", "T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'K-05-M',
                pasos: ["ST-52.3", "T-15", "T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'K-06',
                pasos: ["ST-52.3", "T-15", "T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'K-06 400',
                pasos: ["ST-52.3", "T-15", "T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'K-06 T',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T1',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T2',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-15-3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'PG-1',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'Rollo/Rollo-2',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'S-355-JO',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'SAE-1018',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'ST-52.3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-10',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-11',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-12',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-16',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-20',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-21',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-22',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-5',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-6',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-8B',
                pasos: undefined,
                warning: false
            }
        ]
    },
    {
        key: 'K-06 T1',
        calidadDestino: [
            {
                calidad: '355 PERF',
                pasos: ["ST-52.3", "T-15", "T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'C-1018',
                pasos: ["T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'J2',
                pasos: ["ST-52.3", "T-15", "T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'K-05',
                pasos: ["ST-52.3", "T-15", "T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'K-05-M',
                pasos: ["ST-52.3", "T-15", "T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'K-06',
                pasos: ["ST-52.3", "T-15", "T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'K-06 400',
                pasos: ["ST-52.3", "T-15", "T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'K-06 T',
                pasos: ["ST-52.3", "T-15", "T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'K-06 T1',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T2',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-15-3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'PG-1',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'Rollo/Rollo-2',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'S-355-JO',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'SAE-1018',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'ST-52.3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-10',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-11',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-12',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-16',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-20',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-21',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-22',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-5',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-6',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-8B',
                pasos: undefined,
                warning: false
            }
        ]
    },
    {
        key: 'K-06 T2',
        calidadDestino: [
            {
                calidad: '355 PERF',
                pasos: ["ST-52.3", "T-15", "T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'C-1018',
                pasos: ["T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'J2',
                pasos: ["ST-52.3", "T-15", "T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'K-05',
                pasos: ["ST-52.3", "T-15", "T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'K-05-M',
                pasos: []
                ,
                warning: false
            },
            {
                calidad: 'K-06',
                pasos: ["ST-52.3", "T-15", "T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'K-06 400',
                pasos: []
                ,
                warning: false
            },
            {
                calidad: 'K-06 T',
                pasos: ["ST-52.3", "T-15", "T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'K-06 T1',
                pasos: ["ST-52.3", "T-15", "T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'K-06 T2',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-15-3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'PG-1',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'Rollo/Rollo-2',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'S-355-JO',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'SAE-1018',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'ST-52.3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-10',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-11',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-12',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-16',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-20',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-21',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-22',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-5',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-6',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-8B',
                pasos: undefined,
                warning: false
            }
        ]
    },
    {
        key: 'K-06 T3',
        calidadDestino: [
            {
                calidad: '355 PERF',
                pasos: ["ST-52.3", "T-15", "T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'C-1018',
                pasos: ["T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'J2',
                pasos: ["ST-52.3", "T-15", "T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'K-05',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-05-M',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 400',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T1',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T2',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-15-3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'PG-1',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'Rollo/Rollo-2',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'S-355-JO',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'SAE-1018',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'ST-52.3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-10',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-11',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-12',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-16',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-20',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-21',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-22',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-5',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-6',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-8B',
                pasos: undefined,
                warning: false
            }
        ]
    },
    {
        key: 'K15-3',
        calidadDestino: [
            {
                calidad: '355 PERF',
                pasos: ["ST-52.3", "T-15", "T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'C-1018',
                pasos: ["T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'J2',
                pasos: ["ST-52.3", "T-15", "T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'K-05',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-05-M',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 400',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T1',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T2',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-15-3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'PG-1',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'Rollo/Rollo-2',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'S-355-JO',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'SAE-1018',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'ST-52.3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-10',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-11',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-12',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-16',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-20',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-21',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-22',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-5',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-6',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-8B',
                pasos: undefined,
                warning: false
            }
        ]
    },
    {
        key: 'PG-1',
        calidadDestino: [
            {
                calidad: '355 PERF',
                pasos: ["ST-52.3", "T-15", "T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'C-1018',
                pasos: ["T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'J2',
                pasos: ["ST-52.3", "T-15", "T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'K-05',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-05-M',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 400',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T1',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T2',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-15-3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'PG-1',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'Rollo/Rollo-2',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'S-355-JO',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'SAE-1018',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'ST-52.3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-10',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-11',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-12',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-16',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-20',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-21',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-22',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-5',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-6',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-8B',
                pasos: undefined,
                warning: false
            }
        ]
    },
    {
        key: 'Rollo / Rollo-2',
        calidadDestino: [
            {
                calidad: '355 PERF',
                pasos: ["ST-52.3", "T-15", "T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'C-1018',
                pasos: ["T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'J2',
                pasos: ["ST-52.3", "T-15", "T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'K-05',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-05-M',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 400',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T1',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T2',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-15-3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'PG-1',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'Rollo/Rollo-2',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'S-355-JO',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'SAE-1018',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'ST-52.3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-10',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-11',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-12',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-16',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-20',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-21',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-22',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-5',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-6',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-8B',
                pasos: undefined,
                warning: false
            }
        ]
    },
    {
        key: 'S-355-JO',
        calidadDestino: [
            {
                calidad: '355 PERF',
                pasos: ["ST-52.3", "T-15", "T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'C-1018',
                pasos: ["T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'J2',
                pasos: ["ST-52.3", "T-15", "T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'K-05',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-05-M',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 400',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T1',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T2',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-15-3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'PG-1',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'Rollo/Rollo-2',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'S-355-JO',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'SAE-1018',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'ST-52.3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-10',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-11',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-12',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-16',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-20',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-21',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-22',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-5',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-6',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-8B',
                pasos: undefined,
                warning: false
            }
        ]
    },
    {
        key: 'SAE-1018',
        calidadDestino: [
            {
                calidad: '355 PERF',
                pasos: ["ST-52.3", "T-15", "T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'C-1018',
                pasos: ["T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'J2',
                pasos: ["ST-52.3", "T-15", "T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'K-05',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-05-M',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 400',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T1',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T2',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-15-3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'PG-1',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'Rollo/Rollo-2',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'S-355-JO',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'SAE-1018',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'ST-52.3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-10',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-11',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-12',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-16',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-20',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-21',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-22',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-5',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-6',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-8B',
                pasos: undefined,
                warning: false
            }
        ]
    },
    {
        key: 'ST-52.3',
        calidadDestino: [
            {
                calidad: '355 PERF',
                pasos: ["ST-52.3", "T-15", "T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'C-1018',
                pasos: ["T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'J2',
                pasos: ["ST-52.3", "T-15", "T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'K-05',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-05-M',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 400',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T1',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T2',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-15-3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'PG-1',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'Rollo/Rollo-2',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'S-355-JO',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'SAE-1018',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'ST-52.3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-10',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-11',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-12',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-16',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-20',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-21',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-22',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-5',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-6',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-8B',
                pasos: undefined,
                warning: false
            }
        ]
    },
    {
        key: 'T-10',
        calidadDestino: [
            {
                calidad: '355 PERF',
                pasos: ["ST-52.3", "T-15", "T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'C-1018',
                pasos: ["T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'J2',
                pasos: ["ST-52.3", "T-15", "T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'K-05',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-05-M',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 400',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T1',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T2',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-15-3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'PG-1',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'Rollo/Rollo-2',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'S-355-JO',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'SAE-1018',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'ST-52.3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-10',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-11',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-12',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-16',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-20',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-21',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-22',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-5',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-6',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-8B',
                pasos: undefined,
                warning: false
            }
        ]
    },
    {
        key: 'T-11',
        calidadDestino: [
            {
                calidad: '355 PERF',
                pasos: ["ST-52.3", "T-15", "T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'C-1018',
                pasos: ["T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'J2',
                pasos: ["ST-52.3", "T-15", "T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'K-05',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-05-M',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 400',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T1',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T2',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-15-3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'PG-1',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'Rollo/Rollo-2',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'S-355-JO',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'SAE-1018',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'ST-52.3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-10',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-11',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-12',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-16',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-20',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-21',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-22',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-5',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-6',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-8B',
                pasos: undefined,
                warning: false
            }
        ]
    },
    {
        key: 'T-12',
        calidadDestino: [
            {
                calidad: '355 PERF',
                pasos: ["ST-52.3", "T-15", "T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'C-1018',
                pasos: ["T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'J2',
                pasos: ["ST-52.3", "T-15", "T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'K-05',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-05-M',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 400',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T1',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T2',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-15-3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'PG-1',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'Rollo/Rollo-2',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'S-355-JO',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'SAE-1018',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'ST-52.3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-10',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-11',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-12',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-16',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-20',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-21',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-22',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-5',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-6',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-8B',
                pasos: undefined,
                warning: false
            }
        ]
    },
    {
        key: 'T-16',
        calidadDestino: [
            {
                calidad: '355 PERF',
                pasos: ["ST-52.3", "T-15", "T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'C-1018',
                pasos: ["T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'J2',
                pasos: ["ST-52.3", "T-15", "T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'K-05',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-05-M',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 400',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T1',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T2',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-15-3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'PG-1',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'Rollo/Rollo-2',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'S-355-JO',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'SAE-1018',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'ST-52.3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-10',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-11',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-12',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-16',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-20',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-21',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-22',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-5',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-6',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-8B',
                pasos: undefined,
                warning: false
            }
        ]
    },
    {
        key: 'T-20',
        calidadDestino: [
            {
                calidad: '355 PERF',
                pasos: ["ST-52.3", "T-15", "T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'C-1018',
                pasos: ["T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'J2',
                pasos: ["ST-52.3", "T-15", "T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'K-05',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-05-M',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 400',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T1',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T2',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-15-3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'PG-1',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'Rollo/Rollo-2',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'S-355-JO',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'SAE-1018',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'ST-52.3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-10',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-11',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-12',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-16',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-20',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-21',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-22',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-5',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-6',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-8B',
                pasos: undefined,
                warning: false
            }
        ]
    },
    {
        key: 'T-21',
        calidadDestino: [
            {
                calidad: '355 PERF',
                pasos: ["ST-52.3", "T-15", "T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'C-1018',
                pasos: ["T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'J2',
                pasos: ["ST-52.3", "T-15", "T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'K-05',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-05-M',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 400',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T1',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T2',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-15-3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'PG-1',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'Rollo/Rollo-2',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'S-355-JO',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'SAE-1018',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'ST-52.3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-10',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-11',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-12',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-16',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-20',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-21',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-22',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-5',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-6',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-8B',
                pasos: undefined,
                warning: false
            }
        ]
    },
    {
        key: 'T-22',
        calidadDestino: [
            {
                calidad: '355 PERF',
                pasos: ["ST-52.3", "T-15", "T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'C-1018',
                pasos: ["T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'J2',
                pasos: ["ST-52.3", "T-15", "T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'K-05',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-05-M',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 400',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T1',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T2',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-15-3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'PG-1',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'Rollo/Rollo-2',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'S-355-JO',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'SAE-1018',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'ST-52.3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-10',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-11',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-12',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-16',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-20',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-21',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-22',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-5',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-6',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-8B',
                pasos: undefined,
                warning: false
            }
        ]
    },
    {
        key: 'T-5',
        calidadDestino: [
            {
                calidad: '355 PERF',
                pasos: ["ST-52.3", "T-15", "T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'C-1018',
                pasos: ["T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'J2',
                pasos: ["ST-52.3", "T-15", "T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'K-05',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-05-M',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 400',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T1',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T2',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-15-3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'PG-1',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'Rollo/Rollo-2',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'S-355-JO',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'SAE-1018',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'ST-52.3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-10',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-11',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-12',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-16',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-20',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-21',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-22',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-5',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-6',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-8B',
                pasos: undefined,
                warning: false
            }
        ]
    },
    {
        key: 'T-6',
        calidadDestino: [
            {
                calidad: '355 PERF',
                pasos: ["ST-52.3", "T-15", "T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'C-1018',
                pasos: ["T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'J2',
                pasos: ["ST-52.3", "T-15", "T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'K-05',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-05-M',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 400',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T1',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T2',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-15-3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'PG-1',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'Rollo/Rollo-2',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'S-355-JO',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'SAE-1018',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'ST-52.3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-10',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-11',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-12',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-16',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-20',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-21',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-22',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-5',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-6',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-8B',
                pasos: undefined,
                warning: false
            }
        ]
    },
    {
        key: 'T-8B',
        calidadDestino: [
            {
                calidad: '355 PERF',
                pasos: ["ST-52.3", "T-15", "T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'C-1018',
                pasos: ["T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'J2',
                pasos: ["ST-52.3", "T-15", "T-12", "T-10", "T-5 / T-6", "K-06"]
                ,
                warning: false
            },
            {
                calidad: 'K-05',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-05-M',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 400',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T1',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T2',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-06 T3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'K-15-3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'PG-1',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'Rollo/Rollo-2',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'S-355-JO',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'SAE-1018',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'ST-52.3',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-10',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-11',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-12',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-16',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-20',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-21',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-22',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-5',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-6',
                pasos: undefined
                ,
                warning: false
            },
            {
                calidad: 'T-8B',
                pasos: undefined,
                warning: false
            }
        ]
    }
]

const columns: ColumnsType<DataType> = [
    {
        title: '',
        dataIndex: 'key',
        rowScope: 'row',
        width: 80
    },
    {
        title: '',
        key: 'calidadDestino',
        dataIndex: 'calidadDestino',
        children: [...GetCalidadDestinos()]
    }
];


const MatrizTransicionCalidades = () => {
    return (
        <div style={{ padding: '16px 16px' }}>
            <Title title={HEADERS.QUALITY_MATRIX} />
            <Table size='small' columns={columns} dataSource={data} bordered scroll={{ x: 2600, y: '70vh' }} pagination={false} />
        </div>
    )
}

export default MatrizTransicionCalidades;
