import { EditOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Row, Table, Tabs } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import TabPane from 'antd/es/tabs/TabPane';
import React from 'react';
import Title from '../../components/Title';
import ButtonUpdate from '../../components/ButtonStyled';
import Searcher from '../../components/Searcher';
import { RESOURCES, HEADERS } from '../../constants';

interface DataType {
    key: React.Key;
    centro: string;
    loteColada: string;
    almacen: string;
    codigo: string;
    cantidad: number;
}

const columns: ColumnsType<DataType> = [
    {
        title: 'Centro',
        dataIndex: 'centro',
        key: 'centro'
    },
    {
        title: 'Lote/Núm. Colada',
        dataIndex: 'loteColada',
        key: 'loteColada',
    },
    {
        title: 'Almacén',
        dataIndex: 'almacen',
        key: 'almacen'
    },
    {
        title: 'Codigo',
        dataIndex: 'codigo',
        key: 'codigo'
    },
    {
        title: 'Cantidad',
        dataIndex: 'cantidad',
        key: 'cantidad'
    }
];

const data: DataType[] = [
    {
        key: '1',
        centro: 'SMS',
        loteColada: 'Lote1',
        almacen: 'Almacen1',
        codigo: 'Palanquilla0001',
        cantidad: 1000
    },
    {
        key: '2',
        centro: 'SMS',
        loteColada: 'Lote1',
        almacen: 'Almacen1',
        codigo: 'Palanquilla0002',
        cantidad: 600
    },
    {
        key: '3',
        centro: 'SMS',
        loteColada: 'Lote1',
        almacen: 'Almacen1',
        codigo: 'Palanquilla0003',
        cantidad: 2000
    },
    {
        key: '4',
        centro: 'SMS',
        loteColada: 'Lote1',
        almacen: 'Almacen1',
        codigo: 'Palanquilla0004',
        cantidad: 205
    },
    {
        key: '5',
        centro: 'SMS',
        loteColada: 'Lote1',
        almacen: 'Almacen1',
        codigo: 'Palanquilla0005',
        cantidad: 202
    },
    {
        key: '6',
        centro: 'SMS',
        loteColada: 'Lote2',
        almacen: 'Almacen1',
        codigo: 'Palanquilla0006',
        cantidad: 208
    },
    {
        key: '7',
        centro: 'SMS',
        loteColada: 'Lote2',
        almacen: 'Almacen1',
        codigo: 'Palanquilla0007',
        cantidad: 105
    },
    {
        key: '8',
        centro: 'SMS',
        loteColada: 'Lote1',
        almacen: 'Almacen2',
        codigo: 'Palanquilla0008',
        cantidad: 1030
    },
    {
        key: '9',
        centro: 'SMS',
        loteColada: 'Lote1',
        almacen: 'Almacen2',
        codigo: 'Palanquilla0009',
        cantidad: 600
    },
    {
        key: '10',
        centro: 'SMS',
        loteColada: 'Lote1',
        almacen: 'Almacen2',
        codigo: 'Palanquilla0010',
        cantidad: 2000
    },
    {
        key: '11',
        centro: 'SMS',
        loteColada: 'Lote1',
        almacen: 'Almacen2',
        codigo: 'Palanquilla0011',
        cantidad: 205
    },
    {
        key: '12',
        centro: 'SMS',
        loteColada: 'Lote1',
        almacen: 'Almacen2',
        codigo: 'Palanquilla0012',
        cantidad: 202
    },
    {
        key: '13',
        centro: 'SMS',
        loteColada: 'Lote2',
        almacen: 'Almacen2',
        codigo: 'Palanquilla0013',
        cantidad: 208
    },
    {
        key: '14',
        centro: 'SMS',
        loteColada: 'Lote2',
        almacen: 'Almacen2',
        codigo: 'Palanquilla0014',
        cantidad: 105
    }
];



interface DataTypeSC {
    key: React.Key;
    centroSC: string;
    loteColadaSC: string;
    calidadSC: string;
    codigoSC: string;
    cantidadSC: number;
}

const columnsSC: ColumnsType<DataTypeSC> = [
    {
        title: 'Centro',
        dataIndex: 'centroSC',
        key: 'centroSC'
    },
    {
        title: 'Lote / Num. Colada',
        dataIndex: 'loteColadaSC',
        key: 'loteColadaSC'
    },
    {
        title: 'Calidad',
        dataIndex: 'calidadSC',
        key: 'calidadSC'
    },
    {
        title: 'Código',
        dataIndex: 'codigoSC',
        key: 'codigoSC'
    },
    {
        title: 'Cantidad',
        dataIndex: 'cantidadSC',
        key: 'cantidadSC'
    },
    {
        title: RESOURCES.ACTIONS,
        key: 'action',
        dataIndex: 'action',
        width: 100,
        render: () => <Button><EditOutlined />Editar</Button>
    }

]

const dataSC: DataTypeSC[] = [
    {
        key: 'SC_1',
        centroSC: 'SMS',
        codigoSC: 'SC-001',
        loteColadaSC: 'Lote1',
        calidadSC: 'Calidad1',
        cantidadSC: 1000
    },
    {
        key: 'SC_2',
        centroSC: 'SMS',
        codigoSC: 'SC-001',
        loteColadaSC: 'Lote1',
        calidadSC: 'Calidad1',
        cantidadSC: 1000
    },
]

const StockPalanquillas = () => {
    return (
        <div style={{ padding: '16px 16px', maxHeight: '90%' }}>
            <Title title={HEADERS.BILLET_STOCK} />
            <Row>
                <Col span={4}>
                    <ButtonUpdate fecha='18/04/2023 17:53' />
                </Col>
                <Col span={4} offset={14}>
                    <Searcher />
                </Col>
            </Row>
            <Divider />
            <Tabs tabPosition='top' type="card">
                <TabPane tab="Stock" key={1}>
                    <Table size='small' bordered columns={columns} dataSource={data} />
                </TabPane>
                <TabPane tab="Sin clasificar" key={2}>
                    <Table size='small' bordered columns={columnsSC} dataSource={dataSC} />
                </TabPane>
            </Tabs>
        </div>
    )
}

export default StockPalanquillas;