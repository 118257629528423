import { Layout } from "antd";
import { Outlet } from "react-router-dom";
import HeaderApp from "../Header";
import SideBar from "../SideBar";
import { TemplateLayout } from "./style";

const { Content, Footer} = Layout;
const Template = () => {
    return (
        <TemplateLayout>
            <SideBar />

            <Layout >
                <HeaderApp />

                <Content style={{padding: 16}}>
                    <Outlet />
                </Content>

                {/* <Footer style={{ textAlign: 'center' }}>MPS Acerías ©2023 Created by Aquiles Solutions</Footer> */}
            </Layout>
        </TemplateLayout>
    )
}

export default Template