import { Col, Divider, Row, Tabs } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import TabPane from 'antd/es/tabs/TabPane';
import ButtonUpdate from '../../components/ButtonStyled';
import Searcher from '../../components/Searcher';
import Title from '../../components/Title';
import { HEADERS } from '../../constants';

interface DataType {
    key: React.Key;
    centro: string;
    loteColada: string;
    codigo: string;
    cantidad: number;
}

const columns: ColumnsType<DataType> = [
    {
        title: 'Centro',
        dataIndex: 'centro',
        key: 'centro'
    },
    {
        title: 'Lote/Núm. Colada',
        dataIndex: 'loteColada',
        key: 'loteColada',
    },
    {
        title: 'Codigo',
        dataIndex: 'codigo',
        key: 'codigo'
    },
    {
        title: 'Cantidad',
        dataIndex: 'cantidad',
        key: 'cantidad'
    }
];


const data: DataType[] = [
    {
        key: '1',
        centro: 'SMS',
        loteColada: 'Lote1',
        codigo: 'Palanquilla0001',
        cantidad: 1000
    },
    {
        key: '2',
        centro: 'SMS',
        loteColada: 'Lote1',
        codigo: 'Palanquilla0002',
        cantidad: 600
    },
    {
        key: '3',
        centro: 'SMS',
        loteColada: 'Lote1',
        codigo: 'Palanquilla0003',
        cantidad: 2000
    },
    {
        key: '4',
        centro: 'SMS',
        loteColada: 'Lote1',
        codigo: 'Palanquilla0004',
        cantidad: 205
    },
    {
        key: '5',
        centro: 'SMS',
        loteColada: 'Lote1',
        codigo: 'Palanquilla0005',
        cantidad: 202
    }
];


const data2: DataType[] = [
    {
        key: '1',
        centro: 'SMS',
        loteColada: 'Lote1',
        codigo: 'Palanquilla0001',
        cantidad: 1000
    },
    {
        key: '2',
        centro: 'SMS',
        loteColada: 'Lote1',
        codigo: 'Palanquilla0002',
        cantidad: 600
    }
];


const data3: DataType[] = [
    {
        key: '1',
        centro: 'SMS',
        loteColada: 'Lote1',
        codigo: 'Palanquilla0001',
        cantidad: 1000
    },
    {
        key: '2',
        centro: 'SMS',
        loteColada: 'Lote1',
        codigo: 'Palanquilla0002',
        cantidad: 600
    }
    ,
    {
        key: '2',
        centro: 'SMS',
        loteColada: 'Lote1',
        codigo: 'Palanquilla0003',
        cantidad: 640
    }
];

const PrevisionConsumos = () => {
    return (
        <div style={{ padding: '16px 16px' }}>
            <Title title={HEADERS.CONSUMPTION_PREVISION} />
            <Row>
                <Col span={4}>
                    <ButtonUpdate />
                </Col>
                <Col span={4} offset={14}>
                    <Searcher />
                </Col>
            </Row>
            <Divider />
            <div style={{ padding: '16px 0px' }}>
                <Tabs defaultActiveKey="1" tabPosition='top' type="card">
                    <TabPane tab="Corto" key="2">
                        <div style={{ background: 'white' }}>
                            <Table size='small' bordered dataSource={data} columns={columns} />
                        </div>
                    </TabPane>
                    <TabPane tab="Medio" key="3">
                        <div style={{ background: 'white' }}>
                            <Table size='small' bordered dataSource={data2} columns={columns} />
                        </div>
                    </TabPane>
                    <TabPane tab="Largo" key="4">
                        <div style={{ background: 'white' }}>
                            <Table size='small' bordered dataSource={data3} columns={columns} />
                        </div>
                    </TabPane>
                </Tabs>
            </div>
        </div>
    )
}

export default PrevisionConsumos;