export const API_ENV = {
    dev: {
        appId: 42,
        apiUrls: {
            USERS: 'https://apisecurity',
            MICROSOFT_AUTH: 'http://aquiles-auth/MicrosoftAuth/auth-microsoft',
            ENDPOINT: "http://192.168.1.15:5001"
        }
    },
    production: {
        appId: 1,
        apiUrls: {
            USERS: 'http://aquilessecurityapi',
            MICROSOFT_AUTH: 'http://aquiles-auth/MicrosoftAuth/auth-microsoft',
            ENDPOINT: "http://192.168.1.15:5001"
        }
    }
}

export const SETTINGS = {
    ...API_ENV.dev,

    tokenName: "mpsacerias-token", // * LOCAL STORAGE TOKEN NAME
    organizationId: "4A5C04E3-C0D3-4B96-8469-2C4610CA8C46",

    // MicrosoftLogin
    clientId: 'fbb115b4-3bad-4bef-9184-2f95d68f6cf8',
    redirectUri:'https://mpsacerias.aquilessolutions.com',
    tenantUrl:'https://login.microsoftonline.com/e94d4061-c0f1-48c6-ad70-a1b1b9e3aaa5',
    appName: "MPSAcerias",
    organizationName: "Celsa"
};

export enum LOGIN_TYPE {
    NONE = '',
    MICROSOFT = 'M',
    AQUILES_AUTH = 'A'
}

export enum COOKIE {
    TOKEN = 'token',
    REFRESH_TOKEN = 'refreshToken',
    USERNAME = 'userName',
    LOGIN_TYPE = 'loginType'
}

export enum HEADERS {
    HOME = 'Home',
    DATA = 'Datos',
    SHIFT_SCHEDULE = 'Calendario de turnos',
    ELECTRIC_SCHEDULE = 'Calendario de coste eléctrico',
    BILLET_STOCK = 'Stock de palanquillas',
    PRODUCTION_STATE = 'Estado de las producciones',
    CONSUMPTION_PREVISION = 'Previsión de consumos',
    MASTER_DATA = 'Parámetros maestros',
    BILLETS = 'Palanquillas',
    QUALITY_MATRIX = 'Matriz de calidades',
    MILL_PARAM = 'Parametrización de trenes',
    CC_PARAM = 'Parametrización de coladas',
    TEMPERATURE_PARAM = 'Parametrización de temperaturas',
    PLANIFICATION = 'Planificación',
    CYCLE_SCHEDULE = 'Calendario de ciclos',
    MONTHLY_SUMMARY = 'Resumen mensual',
    CC_ELECTRIC_CONSUMPTION_FORECAST = 'Previsión de CCA y consumo eléctrico',
    ADMINISTRATION = 'Administración',
    USERS = 'Usuarios',
    ROLES = 'Roles',
    PERMISSIONS = 'Permisos'
}

export enum RESOURCES {
    CC1 = 'Colada 1',
    CC2 = 'Colada 2',
    ACTIONS = 'Acciones',
    
    HOURS = 'Horas',
    MINUTES = 'Minutos',
    TN_H = 'TN/h',
    MW = 'MW',
    KW = 'KW',

    MORNING = 'Mañana',
    AFTERNOON = 'Tarde',
    NIGHT = 'Noche',
    CUSTOM = 'Personalizado',
    FROM = 'De',
    TO = 'a',

    MONDAY = 'Lunes',
    TUESDAY = 'Martes',
    WEDNESDAY = 'Miércoles',
    THURSDAY = 'Jueves',
    FRIDAY = 'Viernes',
    SATURDAY = 'Sábado',
    SUNDAY = 'Domingo'
}

export enum VALUES {
    MORNING_SHIFT_INIT = 6,
    AFTERNOON_SHIFT_INIT = 14,
    NIGHT_SHIFT_INIT = 22
}