import { Button, Table } from 'antd';
import { ColumnsType } from "antd/es/table";
import VerticalTimeBar from "./VerticalTimeBar";
import { RESOURCES } from '../../../constants';
import { EditOutlined } from '@ant-design/icons';

const Days = {
    Lunes: RESOURCES.MONDAY,
    Martes: RESOURCES.TUESDAY,
    Miercoles: RESOURCES.WEDNESDAY,
    Jueves: RESOURCES.THURSDAY,
    Viernes: RESOURCES.FRIDAY,
    Sabado: RESOURCES.SATURDAY,
    Domingo: RESOURCES.SUNDAY
}

interface Shift {
    InitialHour: number;
    EndHour: number;
}

interface ShiftMantainment {
    Morning: boolean;
    Afternoon: boolean;
    Night: boolean;
    Custom: Shift | null;
}

interface DataType {
    key: React.Key;
    shift: ShiftMantainment[];
}

const columns: ColumnsType<DataType> = [
    {
        title: 'Turnos de mantenimiento disponibles',
        children: [
            {
                title: Days.Lunes,
                dataIndex: 'shift',
                render: (data: ShiftMantainment[]) => {
                    return (
                        <VerticalTimeBar data={data[0]} />
                    )
                }
            },
            {
                title: Days.Martes,
                dataIndex: 'shift',
                render: (data: ShiftMantainment[]) => {
                    return (
                        <VerticalTimeBar data={data[1]} />
                    )
                }
            },
            {
                title: Days.Miercoles,
                dataIndex: 'shift',
                render: (data: ShiftMantainment[]) => {
                    return (
                        <VerticalTimeBar data={data[2]} />
                    )
                }
            },
            {
                title: Days.Jueves,
                dataIndex: 'shift',
                render: (data: ShiftMantainment[]) => {
                    return (
                        <VerticalTimeBar data={data[3]} />
                    )
                }
            },
            {
                title: Days.Viernes,
                dataIndex: 'shift',
                render: (data: ShiftMantainment[]) => {
                    return (
                        <VerticalTimeBar data={data[4]} />
                    )
                }
            },
            {
                title: Days.Sabado,
                dataIndex: 'shift',
                render: (data: ShiftMantainment[]) => {
                    return (
                        <VerticalTimeBar data={data[5]} />
                    )
                }
            },
            {
                title: Days.Domingo,
                dataIndex: 'shift',
                render: (data: ShiftMantainment[]) => {
                    return (
                        <VerticalTimeBar data={data[6]} />
                    )
                }
            }
        ]
    },
    {
        title: RESOURCES.ACTIONS,
        key: 'action',
        dataIndex: 'action',
        fixed: 'right',
        width: 100,
        render: () => <Button><EditOutlined />Editar</Button>
    }
];

const data: DataType[] = [
    {
        key: '1',
        shift: [
            {
                Morning: true,
                Afternoon: true,
                Night: true,
                Custom: null
            },
            {
                Morning: true,
                Afternoon: false,
                Night: false,
                Custom: null
            },
            {
                Morning: false,
                Afternoon: true,
                Night: false,
                Custom: null
            },
            {
                Morning: false,
                Afternoon: false,
                Night: true,
                Custom: null
            },
            {
                Morning: false,
                Afternoon: false,
                Night: false,
                Custom: {
                    InitialHour: 8,
                    EndHour: 18
                }
            },
            {
                Morning: true,
                Afternoon: false,
                Night: true,
                Custom: null
            },
            {
                Morning: true,
                Afternoon: true,
                Night: false,
                Custom: null
            }
        ]
    }

];



const TablaTurnosMantenimiento = () => {
    return (
        <Table size='small' bordered columns={columns} dataSource={data} pagination={false} scroll={{x: 1780}} />
    )
}

export default TablaTurnosMantenimiento;