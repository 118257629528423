import { Card, Checkbox, Col, InputNumber, Row, Typography } from "antd";
import { RESOURCES, VALUES } from '../../../constants'

interface Shift {
    InitialHour: number;
    EndHour: number;
}

interface ShiftMantainment {
    Morning: boolean;
    Afternoon: boolean;
    Night: boolean;
    Custom: Shift | null;
}

const VerticalTimeBar = (props: { data: ShiftMantainment}) => {
    let hours = [false, false, false, false, false, false, false, false,
        false, false, false, false, false, false, false, false,
        false, false, false, false, false, false, false, false];

    if (props.data.Custom == null) {
        if (props.data.Morning) {
            for (let i = VALUES.MORNING_SHIFT_INIT; i < VALUES.AFTERNOON_SHIFT_INIT; i++)
                hours[i] = true;
        }
        if (props.data.Afternoon) {
            for (let i = VALUES.AFTERNOON_SHIFT_INIT; i < VALUES.NIGHT_SHIFT_INIT; i++)
                hours[i] = true;
        }
        if (props.data.Night) {
            for (let i = 0; i < VALUES.MORNING_SHIFT_INIT; i++)
                hours[i] = true;

            for (let i = VALUES.NIGHT_SHIFT_INIT; i < 24; i++)
                hours[i] = true;
        }
    }
    else {
        if (props.data.Custom.InitialHour <= props.data.Custom.EndHour) {
            for (let i = 0; i < 24; i++) {
                if (i >= props.data.Custom.InitialHour && i <= props.data.Custom.EndHour - 1)
                    hours[i] = true;
            }
        } else {
            for (let i = 0; i < 24; i++) {
                if (i > props.data.Custom.InitialHour - 1 || i <= props.data.Custom.EndHour - 1)
                    hours[i] = true;
            }
        }
    }

    let changed = false;
    return (
        <Row>
            <Col span={4}>
                {
                    hours.map((m, idx) => {

                        let color = 'lightgrey'
                        let hour = '';
                        if (hours.findIndex(f => !f) >= 0) {
                            if (!changed && m) {
                                hour = idx.toString();
                                changed = true;
                            }
                            else if (!m && changed) {
                                hour = idx.toString();
                                changed = false;
                            }
                        }

                        if (m) color = '#ceffca'
    
                        return (
                            <Row style={{ background: color, width: 20, height: 10 }}>
                                {hour}
                            </Row>
                        )

                    })}
            </Col>
            <Col span={20}>
                <Card size="small" style={{ height: 240, padding: '5%' }}>
                    <Row style={{marginTop: 4}}><Checkbox checked={props.data.Morning}>{RESOURCES.MORNING}</Checkbox></Row>
                    <Row style={{marginTop: 4}}><Checkbox checked={props.data.Afternoon}>{RESOURCES.AFTERNOON}</Checkbox></Row>
                    <Row style={{marginTop: 4}}><Checkbox checked={props.data.Night}>{RESOURCES.NIGHT}</Checkbox></Row>
                    <Row style={{marginTop: 24}}>
                        <Col span={24}>
                            <Checkbox checked={props.data.Custom !== null}>{RESOURCES.CUSTOM}</Checkbox>
                        </Col>
                        <Col span={24}>
                            <Row style={{marginTop: 10}}>
                                <Col offset={3} span={5}>
                                    <Typography.Text style={{ textAlign: 'right' }}> {RESOURCES.FROM}: </Typography.Text>
                                </Col>
                                <Col offset={1} span={15}>
                                    <InputNumber style={{ width: 60 }} value={props.data.Custom?.InitialHour} />
                                </Col>
                            </Row>
                            <Row>
                                <Col offset={3} span={5}>
                                    <Typography.Text style={{ textAlign: 'right' }}> {RESOURCES.TO}: </Typography.Text>
                                </Col>
                                <Col offset={1} span={15}>
                                    <InputNumber style={{ width: 60 }} value={props.data.Custom?.EndHour} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
    )
}

export default VerticalTimeBar;