import { Calendar, Select } from "antd";
import styled from "styled-components";

export const SelectRollingMill = styled(Select)`
    width: 285px;
    margin: 10px 1px 10px 1px;
    margin-right: 280px;

    @media (max-width: 768px) {
        width: 100%;
        background-color: white;
        margin: 0px;
    }

    
`

export const CalendarioDeTurnos = styled(Calendar)`
    font-size: 18px;

    & .ant-picker-calendar-header > .ant-radio-group 
    {
        display: none;
    }

    & .ant-select 
    {
        width: 120px;
    }

    & .ant-picker-calendar-header {
        padding: 0;
    }

    // Selected
    & .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-selected > .ant-picker-cell-inner.ant-picker-calendar-date
    {
        background: ${(props) => props.theme.color.lightGray} !important;
        border-color: ${(props) => props.theme.color.red} !important;
    }

    & .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-selected > 
    .ant-picker-cell-inner.ant-picker-calendar-date > 
    .ant-picker-calendar-date-value {
        color: ${(props) => props.theme.color.red} !important;
    }

    // Today
    & .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-today > .ant-picker-cell-inner.ant-picker-calendar-date
    {
        background: ${(props) => props.theme.color.lightBlue} !important;
    }    

    @media (max-width: 768px) {
        width: 100%;
        background-color: white;

        & .ant-select 
        {
            width: 100%;
        }
    }
`

export const FilterHeader = styled.div`
    width: 100%;
    background-color: white;
    margin: 0px 0px -42px 6px;
    display: flex;
    justify-content: flex-end;

    @media (max-width: 768px) {
        margin: 0px 0px 6px 0px;
        display: flex;
        justify-content: normal;
        width: 100%;
        background-color: white;
    }

    // & .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    //     position: relative;
    //     border: 1px solid ${(props) => props.theme.color.red};
    //     background: ${(props) => props.theme.color.red};
    //     color: ${(props) => props.theme.color.white}
    // }
`