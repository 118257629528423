import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import Title from '../../components/Title';
import { HEADERS, RESOURCES } from '../../constants';

interface DataType {
    key: React.Key;
    usuario: string;
    rol: string;
}

const columns: ColumnsType<DataType> = [
    {
        title: 'Usuario',
        dataIndex: 'usuario',
        key: 'usuario',
        width: '25%'
    },
    {
        title: 'Rol',
        dataIndex: 'rol',
        key: 'rol'
    },
    {
        title: RESOURCES.ACTIONS,
        key: 'action',
        dataIndex: 'action',
        width: 100,
        render: () => <Button><EditOutlined />Editar</Button>
    }
];

const data: DataType[] = [
    {
        key: '1',
        usuario: 'Username',
        rol: 'Admin'
    },
    {
        key: '2',
        usuario: 'Usuario001',
        rol: 'Lectura'
    },
    {
        key: '3',
        usuario: 'Usuario002',
        rol: 'Lectura'
    },
    {
        key: '4',
        usuario: 'Usuario003',
        rol: 'Escritura'
    }
];

const Usuarios = () => {
    return (
        <div style={{ padding: '16px 16px' }}>
            <Title title={HEADERS.USERS} />
            <Table size='small' bordered dataSource={data} columns={columns} />
        </div>
    )
}

export default Usuarios;