import styled from "styled-components";
import { Alert, Layout, Spin } from 'antd';
import { Content } from "antd/es/layout/layout";

export const LayoutLogin = styled(Layout)`
  minHeight: 100vh;
  background: ${(props) => props.theme.color.white};
`
export const LoginContainer = styled(Content)`
  min-height: calc(100vh - 200px);
  min-width: 100%;
  margin-top: 60px;
  padding: 10px 20px;
  text-align: center;
  color: ${(props) => props.theme.color.gray}

  & .ant-btn {
    background-color: ${(props) => props.theme.color.primary};
  }

  & .ant-collapse {
    margin: auto;
    width: 216px;
    border: 0px;
    padding: 0px;
    background-color: ${(props) => props.theme.color.white};
    border: 1px solid gray;
    border-radius: inherit;
  }

  & .ant-card-bordered{
    margin: auto;
    width: 200px;
    border: 0px;
    padding: 0px;
  }

  & .ant-card .ant-card-body {
    padding: 0;
  }

  & .ant-collapse>.ant-collapse-item > .ant-collapse-header {
    padding: 6px;
    font-weight: 600;
    color: ${(props) => props.theme.color.gray};
  }
  & .ant-collapse .ant-collapse-content>.ant-collapse-content-box {
    padding: 0px 8px 0px 8px;
  }
`

export const CelsaImgLogo = styled.img`
  max-height: 140px;
`

export const LoginPanel = styled.div`
  width: 0 0 0 0;
  padding: 40px
`

export const SpinMessage = styled.div`
  display: inherit;
  margin-top: 60px;
`