import { Card, Col, Collapse, Divider, Row, Slider, Switch } from 'antd';
import { useState } from "react";
import ButtonUpdate from '../../../components/ButtonStyled';
import DatePickerFilter from '../../../components/DatePickerFilter';
import Title from '../../../components/Title';
import { HEADERS } from '../../../constants';
import ChartLine from './ChartLine';

const { Panel } = Collapse;

const CalendarioElectrico = () => {
    const [sliderDisabled, setSliderDisabled] = useState(true);
    const handleSwitchChange = () => {
        setSliderDisabled(!sliderDisabled);
    };

    return (
        <div style={{ padding: '16px 16px' }}>
            <Title title={HEADERS.ELECTRIC_SCHEDULE}></Title>
            <Row>
                <Col span={20}>
                    <ButtonUpdate fecha='18/04/2023 17:53' />
                </Col>
                <Col span={4}>
                    <DatePickerFilter />
                </Col>
                <Col span={24}>
                    <Card>
                        <Row>
                            <Col span={4}>
                                <div style={{ width: '100%' }}>Usar intervalos de configuración</div>
                                <Switch checked={!sliderDisabled} onChange={handleSwitchChange} />
                            </Col>
                            <Col span={20}>
                                <Collapse activeKey={sliderDisabled ? '' : '1'}>
                                    <Panel header="Intervalos de configuración" key="1">
                                        <Row>
                                            <Col span={6} offset={1}>
                                                Super baja: <Slider max={200} key={"slider2"} range defaultValue={[0, 70]} disabled={sliderDisabled} style={{ width: '100%' }} />
                                            </Col>
                                            <Col span={6} offset={1}>
                                                Baja:       <Slider max={200} key={"slider3"} range defaultValue={[70, 90]} disabled={sliderDisabled} style={{ width: '100%' }} />
                                            </Col>
                                            <Col span={6} offset={1}>
                                                Alta:     <Slider max={200} key={"slider4"} range defaultValue={[90, 200]} disabled={sliderDisabled} style={{ width: '100%' }} />
                                            </Col>
                                        </Row>
                                    </Panel>
                                </Collapse>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>

            <Divider></Divider>
            <ChartLine />
        </div>
    )
}

export default CalendarioElectrico;