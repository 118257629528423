import { AuthService, Login } from "aquiles-security-library"
import { Navigate, useLocation } from "react-router-dom"
import { Collapse, Divider, Spin } from "antd"
import { useAtom } from "jotai"
import jwt from 'jwt-decode'; 
import { useState } from "react"
import MicrosoftLogin from "react-microsoft-login"
import { HeaderContainer } from "../../components/layout/Header/style"
import { COOKIE, LOGIN_TYPE, SETTINGS } from "../../constants"
import { makePostRequest } from "../../lib/apiCalls"
import nonCollapsedImage from '../../resources/Application_Splash_Logo_Celsa.png'
import { loginAtom } from "../../store/atoms"
import { CelsaImgLogo, LayoutLogin, LoginContainer, LoginPanel, SpinMessage } from "./styles"

const { Panel } = Collapse;

const LoginPage = () => {
    const [login, setLoginAtom] = useAtom(loginAtom);

    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setLoading] = useState(false); 

    const [msalInstance, onMsalInstanceChange] = useState();

    const auth = new AuthService();

    let location = useLocation() as any;

    const onFinishAquilesAuth = async () => {
        setLoginAtom('true');
        const userInfo = jwt(auth.getToken(SETTINGS.tokenName) || "") as any;
        let userName = userInfo !== undefined ? userInfo.UserName : "";
        localStorage.setItem(COOKIE.USERNAME, userName);
        localStorage.setItem(COOKIE.LOGIN_TYPE, LOGIN_TYPE.AQUILES_AUTH);
    };

    const loginMicrosoftHandler = async (err: any, data: any, msal: any) => {
        setLoading(true);
        console.log('error', err);
        console.log('data', data);
        console.log('msal', msal);

        // if (err) setErrorMessage(err)
        // else if (errorMessage !== '') setErrorMessage('');

        if (!err && data) {
            // El metodo tiene un throw error para mostrar errores en popup.
            const response = await makePostRequest(SETTINGS.apiUrls.MICROSOFT_AUTH, {
                "microsoftIdToken": data.idToken,
                "organizationName": SETTINGS.organizationName,
                "applicationName": SETTINGS.appName
            }, {});

            console.log(msal);
            onMsalInstanceChange(msal);

            localStorage.setItem(COOKIE.TOKEN, response);
            localStorage.setItem(COOKIE.REFRESH_TOKEN, data.idToken);
            localStorage.setItem(COOKIE.USERNAME, data.account.name)
            localStorage.setItem(COOKIE.LOGIN_TYPE, LOGIN_TYPE.MICROSOFT);

            setLoginAtom('true');
        }

        setLoading(false);
    };

    if (login == 'true') {
        return <Navigate to="/test" />;
    }

    // setTimeout(function() {
    //     setErrorMessage('Lorem ipsum dolor sit amet, consectetur adipisci tempor incidunt ut labore et dolore magna aliqua veniam, quis nostrud exercitation ullamcorpor s commodo consequat. Duis autem vel eum irrure esse molestiae consequat, vel illum dolore eu fugi et iusto odio dignissim qui blandit praesent luptat exceptur sint occaecat cupiditat non provident, deserunt mollit anim id est laborum et dolor fuga distinct. Nam liber tempor cum soluta nobis elige quod maxim placeat facer possim omnis volup')
    // }, 2000);

    return (
        <LayoutLogin className="layout">
            <HeaderContainer />
            <LoginContainer>
                <CelsaImgLogo src={nonCollapsedImage} />
                <Divider />
                <LoginPanel>
                    
                    {/* <MicrosoftLogin redirectUri='http://localhost:3000' clientId='82235e98-331a-4c0b-b950-1d956f695c6e' tenantUrl='https://login.microsoftonline.com/1105bff1-8bd9-4fd7-85ef-75ae2c89fb1f'
                        withUserData={true} authCallback={loginHandler} children={undefined}></MicrosoftLogin> */}
                    {/* <Alert message = { errorMessage } type="error" showIcon  style = { errorMessage == '' ? {display: 'none'} : {display: 'flex'}} /> */}
                    <MicrosoftLogin clientId={SETTINGS.clientId} withUserData={true}
                        tenantUrl={SETTINGS.tenantUrl} authCallback={loginMicrosoftHandler} children={undefined}></MicrosoftLogin>

                    <Collapse defaultActiveKey={['']}>
                        <Panel header="Alt. Login" key="1">
                            <Login
                                settings={SETTINGS}
                                onLoggin={onFinishAquilesAuth}
                                style={{ position: "relative" }}
                                textsProps={{
                                    title: "",
                                    successMessage: "Se ha iniciado sesión",
                                    errorMessage: "Error al iniciar sesión",
                                    errorDescription: "",
                                    userLabel: "Usuario",
                                    userRuleMessage: "Por favor, introduzca un usuario",
                                    passLabel: "Contraseña",
                                    passRuleMessage: "Por favor, introduzca una contraseña",
                                    loginButton: "Iniciar sesión",
                                }} />
                        </Panel>
                    </Collapse>

                    <SpinMessage style={isLoading ? {display: 'block'} : {display: 'none'}}>
                        <Spin tip="Loading">
                            <div className="content" />
                        </Spin>
                    </SpinMessage>
                </LoginPanel>
            </LoginContainer>
        </LayoutLogin>
    )
};

export default LoginPage;

