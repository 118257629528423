import { EditOutlined, SnippetsOutlined } from "@ant-design/icons";
import { Button, Col, Row, Table, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import Title from '../../components/Title';
import { RESOURCES, HEADERS } from '../../constants';

interface HorasStocks {
    centro: string;
    valor: number
}

interface DatosStocks {
    centro: string;
    colada: number | null;
    valor: number;
}

interface Produccion {
    horas: HorasStocks[];
    datosProduccion: DatosStocks[];
    total: number;
}

interface Consumo {
    horas: HorasStocks[];
    datosConsumo: DatosStocks[];
}

interface ProduccionDiff {
    capacidadMax: number;
    diff_capacidad: number;
    aceria: number;
    diff_aceria: number;
}

interface ConsumoTrenesMPS {
    centro: string,
    consumo: number;
    diff: number;
}

interface DataType {
    key: React.Key;
    mes: string;
    produccion: Produccion;
    consumo: Consumo;
    mix: DatosStocks[];
    produccionMPS: ProduccionDiff;
    consumoTrenesMPS: ConsumoTrenesMPS[]
}

const columns: ColumnsType<DataType> = [
    {
        title: 'Mes',
        dataIndex: 'mes',
        key: 'mes',
        fixed: 'left',
        width: 80
    },
    {
        title: 'Producción',
        children: [
            {
                title: 'Horas CC1',
                width: 80,
                dataIndex: 'produccion',
                render: (produccion: Produccion) => {
                    let idx = produccion.horas.findIndex(f => f.centro == RESOURCES.CC1)
                    if (idx < 0) return (<div></div>)
                    return (
                        <div>{produccion.horas[idx].valor}</div>
                    )
                }
            },
            {
                title: 'Horas CC2',
                width: 80,
                dataIndex: 'produccion',
                render: (produccion: Produccion) => {
                    let idx = produccion.horas.findIndex(f => f.centro == RESOURCES.CC2)
                    if (idx < 0) return (<div></div>)
                    return (
                        <div>{produccion.horas[idx].valor}</div>
                    )
                }
            },
            {
                title: RESOURCES.CC1,
                children: [
                    {
                        title: "Total",
                        width: 80,
                        dataIndex: 'produccion',
                        render: (produccion: Produccion) => {
                            let idx = produccion.datosProduccion.findIndex(f => f.centro == "Total" && f.colada == 1);
                            if (idx < 0) return (<div></div>)
                            return (
                                <div style={{ background: '#bfffbe', padding: 14, margin: -8, height: 50, width: 80, textAlign: 'center' }}>
                                    <Typography.Text> {produccion.datosProduccion[idx].valor}</Typography.Text>
                                </div>
                            )
                        }
                    },
                    {
                        title: "Danieli",
                        width: 80,
                        dataIndex: 'produccion',
                        render: (produccion: Produccion) => {
                            let idx = produccion.datosProduccion.findIndex(f => f.centro == "Danieli" && f.colada == 1);
                            if (idx < 0) return (<div></div>)
                            return (
                                <div>{produccion.datosProduccion[idx].valor}</div>
                            )
                        }
                    },
                    {
                        title: "Pomini",
                        width: 80,
                        dataIndex: 'produccion',
                        render: (produccion: Produccion) => {
                            let idx = produccion.datosProduccion.findIndex(f => f.centro == "Pomini" && f.colada == 1);
                            if (idx < 0) return (<div></div>)
                            return (
                                <div>{produccion.datosProduccion[idx].valor}</div>
                            )
                        }
                    },
                    {
                        title: "Venta",
                        width: 80,
                        dataIndex: 'produccion',
                        render: (produccion: Produccion) => {
                            let idx = produccion.datosProduccion.findIndex(f => f.centro == "Venta" && f.colada == 1);
                            if (idx < 0) return (<div></div>)
                            return (
                                <div>{produccion.datosProduccion[idx].valor}</div>
                            )
                        }
                    },
                    {
                        title: "SMS",
                        width: 80,
                        dataIndex: 'produccion',
                        render: (produccion: Produccion) => {
                            let idx = produccion.datosProduccion.findIndex(f => f.centro == "SMS" && f.colada == 1);
                            if (idx < 0) return (<div></div>)
                            return (
                                <div>{produccion.datosProduccion[idx].valor}</div>
                            )
                        }
                    }
                ]
            },
            {
                title: RESOURCES.CC2,
                children: [
                    {
                        title: "Total",
                        width: 80,
                        dataIndex: 'produccion',
                        render: (produccion: Produccion) => {
                            let idx = produccion.datosProduccion.findIndex(f => f.centro == "Total" && f.colada == 2);
                            if (idx < 0) return (<div></div>)
                            return (
                                <div style={{ background: '#bfffbe', padding: 14, margin: -8, height: 50, width: 80, textAlign: 'center' }}>
                                    <Typography.Text> {produccion.datosProduccion[idx].valor}</Typography.Text>
                                </div>
                            )
                        }
                    },
                    {
                        title: "Danieli",
                        width: 80,
                        dataIndex: 'produccion',
                        render: (produccion: Produccion) => {
                            let idx = produccion.datosProduccion.findIndex(f => f.centro == "Danieli" && f.colada == 2);
                            if (idx < 0) return (<div></div>)
                            return (
                                <div>{produccion.datosProduccion[idx].valor}</div>
                            )
                        }
                    },
                    {
                        title: "Pomini",
                        width: 80,
                        dataIndex: 'produccion',
                        render: (produccion: Produccion) => {
                            let idx = produccion.datosProduccion.findIndex(f => f.centro == "Pomini" && f.colada == 2);
                            if (idx < 0) return (<div></div>)
                            return (
                                <div>{produccion.datosProduccion[idx].valor}</div>
                            )
                        }
                    },
                    {
                        title: "SMS",
                        width: 80,
                        dataIndex: 'produccion',
                        render: (produccion: Produccion) => {
                            let idx = produccion.datosProduccion.findIndex(f => f.centro == "SMS" && f.colada == 2);
                            if (idx < 0) return (<div></div>)
                            return (
                                <div>{produccion.datosProduccion[idx].valor}</div>
                            )
                        }
                    }
                ]
            },
            {
                title: 'Total',
                width: 80,
                dataIndex: 'produccion',
                render: (produccion: Produccion) => {
                    return (
                        <div style={{ background: '#bfffbe', padding: 14, margin: -8, height: 50, width: 80, textAlign: 'center' }}>
                            <Typography.Text> {produccion.total}</Typography.Text>
                        </div>
                    )
                }
            },
        ]
    },
    {
        title: 'Consumo',
        children: [
            {
                title: 'Horas Danieli',
                width: 80,
                dataIndex: 'consumo',
                render: (produccion: Consumo) => {
                    let idx = produccion.horas.findIndex(f => f.centro == "Danieli")
                    if (idx < 0) return (<div></div>)
                    return (
                        <div>{produccion.horas[idx].valor}</div>
                    )
                }
            },
            {
                title: 'Horas Pomini',
                width: 80,
                dataIndex: 'consumo',
                render: (produccion: Consumo) => {
                    let idx = produccion.horas.findIndex(f => f.centro == "Pomini")
                    if (idx < 0) return (<div></div>)
                    return (
                        <div>{produccion.horas[idx].valor}</div>
                    )
                }
            },
            {
                title: 'Horas SMS',
                width: 80,
                dataIndex: 'consumo',
                render: (produccion: Consumo) => {
                    let idx = produccion.horas.findIndex(f => f.centro == "SMS")
                    if (idx < 0) return (<div></div>)
                    return (
                        <div>{produccion.horas[idx].valor}</div>
                    )
                }
            },
            {
                title: 'Consumos',
                children: [
                    {
                        title: 'Danieli',
                        width: 80,
                        dataIndex: 'consumo',
                        render: (produccion: Consumo) => {
                            let idx = produccion.datosConsumo.findIndex(f => f.centro == "Danieli");
                            if (idx < 0) return (<div></div>)
                            return (
                                <div>{produccion.datosConsumo[idx].valor}</div>
                            )
                        }
                    },
                    {
                        title: 'Pomini',
                        width: 80,
                        dataIndex: 'consumo',
                        render: (produccion: Consumo) => {
                            let idx = produccion.datosConsumo.findIndex(f => f.centro == "Pomini");
                            if (idx < 0) return (<div></div>)
                            return (
                                <div>{produccion.datosConsumo[idx].valor}</div>
                            )
                        }
                    },
                    {
                        title: 'SMS',
                        width: 80,
                        dataIndex: 'consumo',
                        render: (produccion: Consumo) => {
                            let idx = produccion.datosConsumo.findIndex(f => f.centro == "SMS");
                            if (idx < 0) return (<div></div>)
                            return (
                                <div>{produccion.datosConsumo[idx].valor}</div>
                            )
                        }
                    }
                ]
            }
        ]
    },
    {
        title: 'Mix',
        children: [
            {
                title: 'Danieli',
                width: 80,
                dataIndex: 'mix',
                render: (mix: DatosStocks[]) => {
                    let idx = mix.findIndex(f => f.centro == "Danieli");
                    if (idx < 0) return (<div></div>)
                    return (
                        <div>{mix[idx].valor}</div>
                    )
                }
            },
            {
                title: 'Pomini',
                width: 80,
                dataIndex: 'mix',
                render: (mix: DatosStocks[]) => {
                    let idx = mix.findIndex(f => f.centro == "Pomini");
                    if (idx < 0) return (<div></div>)
                    return (
                        <div>{mix[idx].valor}</div>
                    )
                }
            },
            {
                title: 'SMS',
                width: 80,
                dataIndex: 'mix',
                render: (mix: DatosStocks[]) => {
                    let idx = mix.findIndex(f => f.centro == "SMS");
                    if (idx < 0) return (<div></div>)
                    return (
                        <div>{mix[idx].valor}</div>
                    )
                }
            }
        ]
    },
    {
        title: 'Producción MPS',
        children: [
            {
                title: 'Capacidad Max.',
                width: 80,
                dataIndex: 'produccionMPS',
                render: (produccionMPS: ProduccionDiff) => {
                    return (
                        <div>
                            {produccionMPS.capacidadMax}
                        </div>
                    )
                }
            },
            {
                title: 'Diff',
                width: 80,
                dataIndex: 'produccionMPS',
                render: (produccionMPS: ProduccionDiff) => {
                    return (
                        <div>
                            {produccionMPS.diff_capacidad}
                        </div>
                    )
                }
            },
            {
                title: 'Acería MPS',
                width: 80,
                dataIndex: 'produccionMPS',
                render: (produccionMPS: ProduccionDiff) => {
                    return (
                        <div style={{ background: '#ffe4d1', padding: 14, margin: -8, height: 50, width: 80, textAlign: 'center' }}>
                            {produccionMPS.aceria}
                        </div>
                    )
                }
            },
            {
                title: 'Diff',
                width: 80,
                dataIndex: 'produccionMPS',
                render: (produccionMPS: ProduccionDiff) => {
                    return (
                        <div>
                            {produccionMPS.diff_aceria}
                        </div>
                    )
                }
            }
        ]
    },
    {
        title: 'Consumo trenes MPS',
        children: [
            {
                title: 'Danieli',
                children:
                    [
                        {
                            title: 'Consumo',
                            width: 80,
                            dataIndex: 'consumoTrenesMPS',
                            render(data: ConsumoTrenesMPS[]) {
                                let idx = data.findIndex(f => f.centro == "Danieli");
                                if (idx < 0) return (<div></div>)
                                return (
                                    <div style={{ background: '#e1f4ff', padding: 14, margin: -8, height: 50, width: 80, textAlign: 'center' }}>
                                        {data[idx].consumo}
                                    </div>
                                )
                            }
                        },
                        {
                            title: 'Diff',
                            width: 80,
                            dataIndex: 'consumoTrenesMPS',
                            render(data: ConsumoTrenesMPS[]) {
                                let idx = data.findIndex(f => f.centro == "Danieli");
                                if (idx < 0) return (<div></div>)
                                return (
                                    <div>
                                        {data[idx].diff}
                                    </div>
                                )
                            }
                        },
                    ]

            },
            {
                title: 'Pomini',
                children:
                    [
                        {
                            title: 'Consumo',
                            width: 80,
                            dataIndex: 'consumoTrenesMPS',
                            render(data: ConsumoTrenesMPS[]) {
                                let idx = data.findIndex(f => f.centro == "Pomini");
                                if (idx < 0) return (<div></div>)
                                return (
                                    <div style={{ background: '#e1f4ff', padding: 14, margin: -8, height: 50, width: 80, textAlign: 'center' }}>
                                        {data[idx].consumo}
                                    </div>
                                )
                            }
                        },
                        {
                            title: 'Diff',
                            width: 80,
                            dataIndex: 'consumoTrenesMPS',
                            render(data: ConsumoTrenesMPS[]) {
                                let idx = data.findIndex(f => f.centro == "Pomini");
                                if (idx < 0) return (<div></div>)
                                return (
                                    <div>
                                        {data[idx].diff}
                                    </div>
                                )
                            }
                        },
                    ]

            },
            {
                title: 'SMS',
                children:
                    [
                        {
                            title: 'Consumo',
                            width: 80,
                            dataIndex: 'consumoTrenesMPS',
                            render(data: ConsumoTrenesMPS[]) {
                                let idx = data.findIndex(f => f.centro == "SMS");
                                if (idx < 0) return (<div></div>)
                                return (
                                    <div style={{ background: '#e1f4ff', padding: 14, margin: -8, height: 50, width: 80, textAlign: 'center' }}>
                                        {data[idx].consumo}
                                    </div>
                                )
                            }
                        },
                        {
                            title: 'Diff',
                            width: 80,
                            dataIndex: 'consumoTrenesMPS',
                            render(data: ConsumoTrenesMPS[]) {
                                let idx = data.findIndex(f => f.centro == "SMS");
                                if (idx < 0) return (<div></div>)
                                return (
                                    <div>
                                        {data[idx].diff}
                                    </div>
                                )
                            }
                        }
                    ]

            }
        ]
    },
    {
        title: RESOURCES.ACTIONS,
        key: 'action',
        dataIndex: 'action',
        fixed: 'right',
        width: 100,
        render: () => <Button><EditOutlined />Editar</Button>
    }
];

const data: DataType[] = [
    {
        key: '1',
        mes: 'Enero',
        produccion: {
            horas: [
                {
                    centro: RESOURCES.CC1,
                    valor: 308
                },
                {
                    centro: 'Colada 2',
                    valor: 309
                },
            ],
            datosProduccion: [
                {
                    centro: 'Total',
                    colada: 1,
                    valor: 46395
                },
                {
                    centro: 'Danieli',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'Pomini',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'Venta',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'SMS',
                    colada: 1,
                    valor: 46395
                },
                {
                    centro: 'Total',
                    colada: 2,
                    valor: 43638
                },
                {
                    centro: 'Danieli',
                    colada: 2,
                    valor: 30615
                },
                {
                    centro: 'Pomini',
                    colada: 2,
                    valor: 13023
                },
                {
                    centro: 'SMS',
                    colada: 2,
                    valor: 0
                }
            ],
            total: 90032
        },
        consumo: {
            horas: [
                {
                    centro: 'Danieli',
                    valor: 344
                },
                {
                    centro: 'Pomini',
                    valor: 192
                },
                {
                    centro: 'SMS',
                    valor: 417
                }
            ],
            datosConsumo: [
                {
                    centro: 'Danieli',
                    colada: null,
                    valor: 26639
                },
                {
                    centro: 'Pomini',
                    colada: null,
                    valor: 13518
                },
                {
                    centro: 'SMS',
                    colada: null,
                    valor: 51058
                }
            ]
        },
        mix: [
            {
                centro: 'Danieli',
                colada: null,
                valor: 81.65
            },
            {
                centro: 'Pomini',
                colada: null,
                valor: 81.65
            },
            {
                centro: 'SMS',
                colada: null,
                valor: 81.65
            }
        ],
        produccionMPS: {
            capacidadMax: 118269,
            diff_capacidad: 28237,
            aceria: 89479,
            diff_aceria: 553
        },
        consumoTrenesMPS: [
            {
                centro: 'Danieli',
                consumo: 25948,
                diff: 691
            },
            {
                centro: 'Pomini',
                consumo: 13218,
                diff: 300
            },
            {
                centro: 'SMS',
                consumo: 50947,
                diff: 111
            }
        ]
    },
    {
        key: '1',
        mes: 'Enero',
        produccion: {
            horas: [
                {
                    centro: RESOURCES.CC1,
                    valor: 308
                },
                {
                    centro: 'Colada 2',
                    valor: 309
                },
            ],
            datosProduccion: [
                {
                    centro: 'Total',
                    colada: 1,
                    valor: 46395
                },
                {
                    centro: 'Danieli',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'Pomini',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'Venta',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'SMS',
                    colada: 1,
                    valor: 46395
                },
                {
                    centro: 'Total',
                    colada: 2,
                    valor: 43638
                },
                {
                    centro: 'Danieli',
                    colada: 2,
                    valor: 30615
                },
                {
                    centro: 'Pomini',
                    colada: 2,
                    valor: 13023
                },
                {
                    centro: 'SMS',
                    colada: 2,
                    valor: 0
                }
            ],
            total: 90032
        },
        consumo: {
            horas: [
                {
                    centro: 'Danieli',
                    valor: 344
                },
                {
                    centro: 'Pomini',
                    valor: 192
                },
                {
                    centro: 'SMS',
                    valor: 417
                }
            ],
            datosConsumo: [
                {
                    centro: 'Danieli',
                    colada: null,
                    valor: 26639
                },
                {
                    centro: 'Pomini',
                    colada: null,
                    valor: 13518
                },
                {
                    centro: 'SMS',
                    colada: null,
                    valor: 51058
                }
            ]
        },
        mix: [
            {
                centro: 'Danieli',
                colada: null,
                valor: 81.65
            },
            {
                centro: 'Pomini',
                colada: null,
                valor: 81.65
            },
            {
                centro: 'SMS',
                colada: null,
                valor: 81.65
            }
        ],
        produccionMPS: {
            capacidadMax: 118269,
            diff_capacidad: 28237,
            aceria: 89479,
            diff_aceria: 553
        },
        consumoTrenesMPS: [
            {
                centro: 'Danieli',
                consumo: 25948,
                diff: 691
            },
            {
                centro: 'Pomini',
                consumo: 13218,
                diff: 300
            },
            {
                centro: 'SMS',
                consumo: 50947,
                diff: 111
            }
        ]
    },
    {
        key: '1',
        mes: 'Enero',
        produccion: {
            horas: [
                {
                    centro: RESOURCES.CC1,
                    valor: 308
                },
                {
                    centro: 'Colada 2',
                    valor: 309
                },
            ],
            datosProduccion: [
                {
                    centro: 'Total',
                    colada: 1,
                    valor: 46395
                },
                {
                    centro: 'Danieli',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'Pomini',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'Venta',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'SMS',
                    colada: 1,
                    valor: 46395
                },
                {
                    centro: 'Total',
                    colada: 2,
                    valor: 43638
                },
                {
                    centro: 'Danieli',
                    colada: 2,
                    valor: 30615
                },
                {
                    centro: 'Pomini',
                    colada: 2,
                    valor: 13023
                },
                {
                    centro: 'SMS',
                    colada: 2,
                    valor: 0
                }
            ],
            total: 90032
        },
        consumo: {
            horas: [
                {
                    centro: 'Danieli',
                    valor: 344
                },
                {
                    centro: 'Pomini',
                    valor: 192
                },
                {
                    centro: 'SMS',
                    valor: 417
                }
            ],
            datosConsumo: [
                {
                    centro: 'Danieli',
                    colada: null,
                    valor: 26639
                },
                {
                    centro: 'Pomini',
                    colada: null,
                    valor: 13518
                },
                {
                    centro: 'SMS',
                    colada: null,
                    valor: 51058
                }
            ]
        },
        mix: [
            {
                centro: 'Danieli',
                colada: null,
                valor: 81.65
            },
            {
                centro: 'Pomini',
                colada: null,
                valor: 81.65
            },
            {
                centro: 'SMS',
                colada: null,
                valor: 81.65
            }
        ],
        produccionMPS: {
            capacidadMax: 118269,
            diff_capacidad: 28237,
            aceria: 89479,
            diff_aceria: 553
        },
        consumoTrenesMPS: [
            {
                centro: 'Danieli',
                consumo: 25948,
                diff: 691
            },
            {
                centro: 'Pomini',
                consumo: 13218,
                diff: 300
            },
            {
                centro: 'SMS',
                consumo: 50947,
                diff: 111
            }
        ]
    },
    {
        key: '1',
        mes: 'Enero',
        produccion: {
            horas: [
                {
                    centro: RESOURCES.CC1,
                    valor: 308
                },
                {
                    centro: 'Colada 2',
                    valor: 309
                },
            ],
            datosProduccion: [
                {
                    centro: 'Total',
                    colada: 1,
                    valor: 46395
                },
                {
                    centro: 'Danieli',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'Pomini',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'Venta',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'SMS',
                    colada: 1,
                    valor: 46395
                },
                {
                    centro: 'Total',
                    colada: 2,
                    valor: 43638
                },
                {
                    centro: 'Danieli',
                    colada: 2,
                    valor: 30615
                },
                {
                    centro: 'Pomini',
                    colada: 2,
                    valor: 13023
                },
                {
                    centro: 'SMS',
                    colada: 2,
                    valor: 0
                }
            ],
            total: 90032
        },
        consumo: {
            horas: [
                {
                    centro: 'Danieli',
                    valor: 344
                },
                {
                    centro: 'Pomini',
                    valor: 192
                },
                {
                    centro: 'SMS',
                    valor: 417
                }
            ],
            datosConsumo: [
                {
                    centro: 'Danieli',
                    colada: null,
                    valor: 26639
                },
                {
                    centro: 'Pomini',
                    colada: null,
                    valor: 13518
                },
                {
                    centro: 'SMS',
                    colada: null,
                    valor: 51058
                }
            ]
        },
        mix: [
            {
                centro: 'Danieli',
                colada: null,
                valor: 81.65
            },
            {
                centro: 'Pomini',
                colada: null,
                valor: 81.65
            },
            {
                centro: 'SMS',
                colada: null,
                valor: 81.65
            }
        ],
        produccionMPS: {
            capacidadMax: 118269,
            diff_capacidad: 28237,
            aceria: 89479,
            diff_aceria: 553
        },
        consumoTrenesMPS: [
            {
                centro: 'Danieli',
                consumo: 25948,
                diff: 691
            },
            {
                centro: 'Pomini',
                consumo: 13218,
                diff: 300
            },
            {
                centro: 'SMS',
                consumo: 50947,
                diff: 111
            }
        ]
    },
    {
        key: '1',
        mes: 'Enero',
        produccion: {
            horas: [
                {
                    centro: RESOURCES.CC1,
                    valor: 308
                },
                {
                    centro: 'Colada 2',
                    valor: 309
                },
            ],
            datosProduccion: [
                {
                    centro: 'Total',
                    colada: 1,
                    valor: 46395
                },
                {
                    centro: 'Danieli',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'Pomini',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'Venta',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'SMS',
                    colada: 1,
                    valor: 46395
                },
                {
                    centro: 'Total',
                    colada: 2,
                    valor: 43638
                },
                {
                    centro: 'Danieli',
                    colada: 2,
                    valor: 30615
                },
                {
                    centro: 'Pomini',
                    colada: 2,
                    valor: 13023
                },
                {
                    centro: 'SMS',
                    colada: 2,
                    valor: 0
                }
            ],
            total: 90032
        },
        consumo: {
            horas: [
                {
                    centro: 'Danieli',
                    valor: 344
                },
                {
                    centro: 'Pomini',
                    valor: 192
                },
                {
                    centro: 'SMS',
                    valor: 417
                }
            ],
            datosConsumo: [
                {
                    centro: 'Danieli',
                    colada: null,
                    valor: 26639
                },
                {
                    centro: 'Pomini',
                    colada: null,
                    valor: 13518
                },
                {
                    centro: 'SMS',
                    colada: null,
                    valor: 51058
                }
            ]
        },
        mix: [
            {
                centro: 'Danieli',
                colada: null,
                valor: 81.65
            },
            {
                centro: 'Pomini',
                colada: null,
                valor: 81.65
            },
            {
                centro: 'SMS',
                colada: null,
                valor: 81.65
            }
        ],
        produccionMPS: {
            capacidadMax: 118269,
            diff_capacidad: 28237,
            aceria: 89479,
            diff_aceria: 553
        },
        consumoTrenesMPS: [
            {
                centro: 'Danieli',
                consumo: 25948,
                diff: 691
            },
            {
                centro: 'Pomini',
                consumo: 13218,
                diff: 300
            },
            {
                centro: 'SMS',
                consumo: 50947,
                diff: 111
            }
        ]
    },
    {
        key: '1',
        mes: 'Enero',
        produccion: {
            horas: [
                {
                    centro: RESOURCES.CC1,
                    valor: 308
                },
                {
                    centro: 'Colada 2',
                    valor: 309
                },
            ],
            datosProduccion: [
                {
                    centro: 'Total',
                    colada: 1,
                    valor: 46395
                },
                {
                    centro: 'Danieli',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'Pomini',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'Venta',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'SMS',
                    colada: 1,
                    valor: 46395
                },
                {
                    centro: 'Total',
                    colada: 2,
                    valor: 43638
                },
                {
                    centro: 'Danieli',
                    colada: 2,
                    valor: 30615
                },
                {
                    centro: 'Pomini',
                    colada: 2,
                    valor: 13023
                },
                {
                    centro: 'SMS',
                    colada: 2,
                    valor: 0
                }
            ],
            total: 90032
        },
        consumo: {
            horas: [
                {
                    centro: 'Danieli',
                    valor: 344
                },
                {
                    centro: 'Pomini',
                    valor: 192
                },
                {
                    centro: 'SMS',
                    valor: 417
                }
            ],
            datosConsumo: [
                {
                    centro: 'Danieli',
                    colada: null,
                    valor: 26639
                },
                {
                    centro: 'Pomini',
                    colada: null,
                    valor: 13518
                },
                {
                    centro: 'SMS',
                    colada: null,
                    valor: 51058
                }
            ]
        },
        mix: [
            {
                centro: 'Danieli',
                colada: null,
                valor: 81.65
            },
            {
                centro: 'Pomini',
                colada: null,
                valor: 81.65
            },
            {
                centro: 'SMS',
                colada: null,
                valor: 81.65
            }
        ],
        produccionMPS: {
            capacidadMax: 118269,
            diff_capacidad: 28237,
            aceria: 89479,
            diff_aceria: 553
        },
        consumoTrenesMPS: [
            {
                centro: 'Danieli',
                consumo: 25948,
                diff: 691
            },
            {
                centro: 'Pomini',
                consumo: 13218,
                diff: 300
            },
            {
                centro: 'SMS',
                consumo: 50947,
                diff: 111
            }
        ]
    },
    {
        key: '1',
        mes: 'Enero',
        produccion: {
            horas: [
                {
                    centro: RESOURCES.CC1,
                    valor: 308
                },
                {
                    centro: 'Colada 2',
                    valor: 309
                },
            ],
            datosProduccion: [
                {
                    centro: 'Total',
                    colada: 1,
                    valor: 46395
                },
                {
                    centro: 'Danieli',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'Pomini',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'Venta',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'SMS',
                    colada: 1,
                    valor: 46395
                },
                {
                    centro: 'Total',
                    colada: 2,
                    valor: 43638
                },
                {
                    centro: 'Danieli',
                    colada: 2,
                    valor: 30615
                },
                {
                    centro: 'Pomini',
                    colada: 2,
                    valor: 13023
                },
                {
                    centro: 'SMS',
                    colada: 2,
                    valor: 0
                }
            ],
            total: 90032
        },
        consumo: {
            horas: [
                {
                    centro: 'Danieli',
                    valor: 344
                },
                {
                    centro: 'Pomini',
                    valor: 192
                },
                {
                    centro: 'SMS',
                    valor: 417
                }
            ],
            datosConsumo: [
                {
                    centro: 'Danieli',
                    colada: null,
                    valor: 26639
                },
                {
                    centro: 'Pomini',
                    colada: null,
                    valor: 13518
                },
                {
                    centro: 'SMS',
                    colada: null,
                    valor: 51058
                }
            ]
        },
        mix: [
            {
                centro: 'Danieli',
                colada: null,
                valor: 81.65
            },
            {
                centro: 'Pomini',
                colada: null,
                valor: 81.65
            },
            {
                centro: 'SMS',
                colada: null,
                valor: 81.65
            }
        ],
        produccionMPS: {
            capacidadMax: 118269,
            diff_capacidad: 28237,
            aceria: 89479,
            diff_aceria: 553
        },
        consumoTrenesMPS: [
            {
                centro: 'Danieli',
                consumo: 25948,
                diff: 691
            },
            {
                centro: 'Pomini',
                consumo: 13218,
                diff: 300
            },
            {
                centro: 'SMS',
                consumo: 50947,
                diff: 111
            }
        ]
    },
    {
        key: '1',
        mes: 'Enero',
        produccion: {
            horas: [
                {
                    centro: RESOURCES.CC1,
                    valor: 308
                },
                {
                    centro: 'Colada 2',
                    valor: 309
                },
            ],
            datosProduccion: [
                {
                    centro: 'Total',
                    colada: 1,
                    valor: 46395
                },
                {
                    centro: 'Danieli',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'Pomini',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'Venta',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'SMS',
                    colada: 1,
                    valor: 46395
                },
                {
                    centro: 'Total',
                    colada: 2,
                    valor: 43638
                },
                {
                    centro: 'Danieli',
                    colada: 2,
                    valor: 30615
                },
                {
                    centro: 'Pomini',
                    colada: 2,
                    valor: 13023
                },
                {
                    centro: 'SMS',
                    colada: 2,
                    valor: 0
                }
            ],
            total: 90032
        },
        consumo: {
            horas: [
                {
                    centro: 'Danieli',
                    valor: 344
                },
                {
                    centro: 'Pomini',
                    valor: 192
                },
                {
                    centro: 'SMS',
                    valor: 417
                }
            ],
            datosConsumo: [
                {
                    centro: 'Danieli',
                    colada: null,
                    valor: 26639
                },
                {
                    centro: 'Pomini',
                    colada: null,
                    valor: 13518
                },
                {
                    centro: 'SMS',
                    colada: null,
                    valor: 51058
                }
            ]
        },
        mix: [
            {
                centro: 'Danieli',
                colada: null,
                valor: 81.65
            },
            {
                centro: 'Pomini',
                colada: null,
                valor: 81.65
            },
            {
                centro: 'SMS',
                colada: null,
                valor: 81.65
            }
        ],
        produccionMPS: {
            capacidadMax: 118269,
            diff_capacidad: 28237,
            aceria: 89479,
            diff_aceria: 553
        },
        consumoTrenesMPS: [
            {
                centro: 'Danieli',
                consumo: 25948,
                diff: 691
            },
            {
                centro: 'Pomini',
                consumo: 13218,
                diff: 300
            },
            {
                centro: 'SMS',
                consumo: 50947,
                diff: 111
            }
        ]
    },
    {
        key: '1',
        mes: 'Enero',
        produccion: {
            horas: [
                {
                    centro: RESOURCES.CC1,
                    valor: 308
                },
                {
                    centro: 'Colada 2',
                    valor: 309
                },
            ],
            datosProduccion: [
                {
                    centro: 'Total',
                    colada: 1,
                    valor: 46395
                },
                {
                    centro: 'Danieli',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'Pomini',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'Venta',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'SMS',
                    colada: 1,
                    valor: 46395
                },
                {
                    centro: 'Total',
                    colada: 2,
                    valor: 43638
                },
                {
                    centro: 'Danieli',
                    colada: 2,
                    valor: 30615
                },
                {
                    centro: 'Pomini',
                    colada: 2,
                    valor: 13023
                },
                {
                    centro: 'SMS',
                    colada: 2,
                    valor: 0
                }
            ],
            total: 90032
        },
        consumo: {
            horas: [
                {
                    centro: 'Danieli',
                    valor: 344
                },
                {
                    centro: 'Pomini',
                    valor: 192
                },
                {
                    centro: 'SMS',
                    valor: 417
                }
            ],
            datosConsumo: [
                {
                    centro: 'Danieli',
                    colada: null,
                    valor: 26639
                },
                {
                    centro: 'Pomini',
                    colada: null,
                    valor: 13518
                },
                {
                    centro: 'SMS',
                    colada: null,
                    valor: 51058
                }
            ]
        },
        mix: [
            {
                centro: 'Danieli',
                colada: null,
                valor: 81.65
            },
            {
                centro: 'Pomini',
                colada: null,
                valor: 81.65
            },
            {
                centro: 'SMS',
                colada: null,
                valor: 81.65
            }
        ],
        produccionMPS: {
            capacidadMax: 118269,
            diff_capacidad: 28237,
            aceria: 89479,
            diff_aceria: 553
        },
        consumoTrenesMPS: [
            {
                centro: 'Danieli',
                consumo: 25948,
                diff: 691
            },
            {
                centro: 'Pomini',
                consumo: 13218,
                diff: 300
            },
            {
                centro: 'SMS',
                consumo: 50947,
                diff: 111
            }
        ]
    },
    {
        key: '1',
        mes: 'Enero',
        produccion: {
            horas: [
                {
                    centro: RESOURCES.CC1,
                    valor: 308
                },
                {
                    centro: 'Colada 2',
                    valor: 309
                },
            ],
            datosProduccion: [
                {
                    centro: 'Total',
                    colada: 1,
                    valor: 46395
                },
                {
                    centro: 'Danieli',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'Pomini',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'Venta',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'SMS',
                    colada: 1,
                    valor: 46395
                },
                {
                    centro: 'Total',
                    colada: 2,
                    valor: 43638
                },
                {
                    centro: 'Danieli',
                    colada: 2,
                    valor: 30615
                },
                {
                    centro: 'Pomini',
                    colada: 2,
                    valor: 13023
                },
                {
                    centro: 'SMS',
                    colada: 2,
                    valor: 0
                }
            ],
            total: 90032
        },
        consumo: {
            horas: [
                {
                    centro: 'Danieli',
                    valor: 344
                },
                {
                    centro: 'Pomini',
                    valor: 192
                },
                {
                    centro: 'SMS',
                    valor: 417
                }
            ],
            datosConsumo: [
                {
                    centro: 'Danieli',
                    colada: null,
                    valor: 26639
                },
                {
                    centro: 'Pomini',
                    colada: null,
                    valor: 13518
                },
                {
                    centro: 'SMS',
                    colada: null,
                    valor: 51058
                }
            ]
        },
        mix: [
            {
                centro: 'Danieli',
                colada: null,
                valor: 81.65
            },
            {
                centro: 'Pomini',
                colada: null,
                valor: 81.65
            },
            {
                centro: 'SMS',
                colada: null,
                valor: 81.65
            }
        ],
        produccionMPS: {
            capacidadMax: 118269,
            diff_capacidad: 28237,
            aceria: 89479,
            diff_aceria: 553
        },
        consumoTrenesMPS: [
            {
                centro: 'Danieli',
                consumo: 25948,
                diff: 691
            },
            {
                centro: 'Pomini',
                consumo: 13218,
                diff: 300
            },
            {
                centro: 'SMS',
                consumo: 50947,
                diff: 111
            }
        ]
    },
    {
        key: '1',
        mes: 'Enero',
        produccion: {
            horas: [
                {
                    centro: RESOURCES.CC1,
                    valor: 308
                },
                {
                    centro: 'Colada 2',
                    valor: 309
                },
            ],
            datosProduccion: [
                {
                    centro: 'Total',
                    colada: 1,
                    valor: 46395
                },
                {
                    centro: 'Danieli',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'Pomini',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'Venta',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'SMS',
                    colada: 1,
                    valor: 46395
                },
                {
                    centro: 'Total',
                    colada: 2,
                    valor: 43638
                },
                {
                    centro: 'Danieli',
                    colada: 2,
                    valor: 30615
                },
                {
                    centro: 'Pomini',
                    colada: 2,
                    valor: 13023
                },
                {
                    centro: 'SMS',
                    colada: 2,
                    valor: 0
                }
            ],
            total: 90032
        },
        consumo: {
            horas: [
                {
                    centro: 'Danieli',
                    valor: 344
                },
                {
                    centro: 'Pomini',
                    valor: 192
                },
                {
                    centro: 'SMS',
                    valor: 417
                }
            ],
            datosConsumo: [
                {
                    centro: 'Danieli',
                    colada: null,
                    valor: 26639
                },
                {
                    centro: 'Pomini',
                    colada: null,
                    valor: 13518
                },
                {
                    centro: 'SMS',
                    colada: null,
                    valor: 51058
                }
            ]
        },
        mix: [
            {
                centro: 'Danieli',
                colada: null,
                valor: 81.65
            },
            {
                centro: 'Pomini',
                colada: null,
                valor: 81.65
            },
            {
                centro: 'SMS',
                colada: null,
                valor: 81.65
            }
        ],
        produccionMPS: {
            capacidadMax: 118269,
            diff_capacidad: 28237,
            aceria: 89479,
            diff_aceria: 553
        },
        consumoTrenesMPS: [
            {
                centro: 'Danieli',
                consumo: 25948,
                diff: 691
            },
            {
                centro: 'Pomini',
                consumo: 13218,
                diff: 300
            },
            {
                centro: 'SMS',
                consumo: 50947,
                diff: 111
            }
        ]
    },
    {
        key: '1',
        mes: 'Enero',
        produccion: {
            horas: [
                {
                    centro: RESOURCES.CC1,
                    valor: 308
                },
                {
                    centro: 'Colada 2',
                    valor: 309
                },
            ],
            datosProduccion: [
                {
                    centro: 'Total',
                    colada: 1,
                    valor: 46395
                },
                {
                    centro: 'Danieli',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'Pomini',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'Venta',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'SMS',
                    colada: 1,
                    valor: 46395
                },
                {
                    centro: 'Total',
                    colada: 2,
                    valor: 43638
                },
                {
                    centro: 'Danieli',
                    colada: 2,
                    valor: 30615
                },
                {
                    centro: 'Pomini',
                    colada: 2,
                    valor: 13023
                },
                {
                    centro: 'SMS',
                    colada: 2,
                    valor: 0
                }
            ],
            total: 90032
        },
        consumo: {
            horas: [
                {
                    centro: 'Danieli',
                    valor: 344
                },
                {
                    centro: 'Pomini',
                    valor: 192
                },
                {
                    centro: 'SMS',
                    valor: 417
                }
            ],
            datosConsumo: [
                {
                    centro: 'Danieli',
                    colada: null,
                    valor: 26639
                },
                {
                    centro: 'Pomini',
                    colada: null,
                    valor: 13518
                },
                {
                    centro: 'SMS',
                    colada: null,
                    valor: 51058
                }
            ]
        },
        mix: [
            {
                centro: 'Danieli',
                colada: null,
                valor: 81.65
            },
            {
                centro: 'Pomini',
                colada: null,
                valor: 81.65
            },
            {
                centro: 'SMS',
                colada: null,
                valor: 81.65
            }
        ],
        produccionMPS: {
            capacidadMax: 118269,
            diff_capacidad: 28237,
            aceria: 89479,
            diff_aceria: 553
        },
        consumoTrenesMPS: [
            {
                centro: 'Danieli',
                consumo: 25948,
                diff: 691
            },
            {
                centro: 'Pomini',
                consumo: 13218,
                diff: 300
            },
            {
                centro: 'SMS',
                consumo: 50947,
                diff: 111
            }
        ]
    }
];



const ResumenMensual = () => {
    return (
        <div style={{ padding: '16px 16px' }}>
            <Title title={HEADERS.MONTHLY_SUMMARY} />
            <Row>
                <Col span={20}>
                    <Button type="primary" ><SnippetsOutlined />Gestionar planes</Button>
                    {/* <BotonActualizar fecha='18/04/2023 17:53' /> */}
                </Col>
            </Row>
            <Table size='small' bordered sticky dataSource={data} columns={columns} pagination={false} scroll={{ x: 1800 }} />
        </div>
    )
}

export default ResumenMensual;