import {
  ContactsOutlined,
  ControlOutlined,
  DatabaseOutlined,
  HomeOutlined,
  TableOutlined
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Layout, Menu } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { HEADERS } from '../../../constants';
import nonCollapsedImage from '../../../resources/Application_Splash_Logo_Celsa.png';
import collapsedImage from '../../../resources/LogoCelsa.png';
import { Img, SideBarContainer } from './style';

const { Sider } = Layout;

function getItem(
  label: React.ReactNode,
  key: string,
  icon?: React.ReactNode,
  children?: MenuItem[],
): MenuItem {
  return {
    key,
    icon,
    children,
    label:
      <div>
        {key.includes('//') ? <p>{label}</p> :
          <Link to={key}>
            {label}
          </Link>}
      </div>,
  } as MenuItem;
}

type MenuItem = Required<MenuProps>['items'][number];

const SideBar = () => {
  const [collapsed, setCollapsed] = useState(false);

  const items: MenuItem[] = [
    getItem(HEADERS.HOME, '/', <HomeOutlined />),
    getItem(HEADERS.DATA, '//2', <DatabaseOutlined />, [
      getItem(HEADERS.SHIFT_SCHEDULE, 'CalendarioTurnos'),
      getItem(HEADERS.ELECTRIC_SCHEDULE, 'CalendarioPrecioElectrico'),
      getItem(HEADERS.BILLET_STOCK, 'StockPalanquillas'),
      getItem(HEADERS.PRODUCTION_STATE, 'EstadoProducciones'),
      getItem(HEADERS.CONSUMPTION_PREVISION, 'PrevisionConsumos'),
      getItem(HEADERS.MASTER_DATA, '//3', <ControlOutlined />, [
        getItem(HEADERS.BILLETS, 'Palanquillas'),
        getItem(HEADERS.QUALITY_MATRIX, 'MatrizTransicionCalidades'),
        getItem(HEADERS.MILL_PARAM, 'HorasTrabajo'),
        getItem(HEADERS.CC_PARAM, 'ParametrizacionColada'),
        getItem(HEADERS.TEMPERATURE_PARAM, 'ParametrizacionTemperaturas')
      ]),
    ]),

    getItem(HEADERS.PLANIFICATION, '//4', <TableOutlined />, [
      getItem(HEADERS.CYCLE_SCHEDULE, 'CalendarioCiclos'),
      getItem(HEADERS.MONTHLY_SUMMARY, 'ResumenMensual'),
      getItem(HEADERS.CC_ELECTRIC_CONSUMPTION_FORECAST, 'PrevisionCCyCE')
    ]),

    getItem(HEADERS.ADMINISTRATION, '//1', <ContactsOutlined />, [
      getItem(HEADERS.USERS, 'Usuarios'),
      getItem(HEADERS.ROLES, 'Roles'),
      getItem(HEADERS.PERMISSIONS, 'Permisos'),
    ]),
  ];

  return <SideBarContainer collapsible collapsed={collapsed} width={300} onCollapse={(value) => setCollapsed(value)} style={{ background: 'white' }}>
      {collapsed ? 
        <Img src={collapsedImage}  /> :
        <Img src={nonCollapsedImage} />}

      <Menu defaultSelectedKeys={['1']} mode="inline" items={items} />
    </SideBarContainer>

}

export default SideBar;