import { Row, Select } from 'antd';
import type { Dayjs } from 'dayjs';
import type { CellRenderInfo } from 'rc-picker/lib/interface';
import ButtonUpdate from '../../../components/ButtonStyled';
import Title from '../../../components/Title';
import { HEADERS } from '../../../constants';
import { CalendarioDeTurnos, FilterHeader, SelectRollingMill } from './style';
import { getRollingMills, getShiftsByRollingMill} from '../../../lib/apiMPS';
import { useEffect, useState } from 'react';
import ErrorPage from '../../../components/ErrorPage';
import Loading from '../../../components/Loading';


const CalenadrioTurnos = () => {
  const [loading, setLoadDataState] = useState(0);
  const [selectedRollingMill, setSelectedRollingMill] = useState(1);

  // TODO
  const [rm, setRollingMills] = useState([]);

  const rollingMills = [
    { value: '1', label: 'Apple' },
    { value: '2', label: 'Banana' },
    { value: '3', label: 'Orange' }
  ]; 

  // = getRollingMills();

  const loadRollingMills = async () => {
    try 
    {
      console.log("Getting data from API. GetRollingMills");
      // TODO
      // const response = await getRollingMills();
      
      // setRollingMills(response.map((item: any) => item.customer))

      setLoadDataState(1);
    } 
    catch(err) 
    {
      setLoadDataState(-1);
      console.log(err);
    }
  }

  const loadShiftsByRollingMill = async () => {
    try 
    {
      console.log("Getting data from API. GetShiftsByRollingMill");
      if (loading == 1) {
        
        // const response = await getShiftsByRollingMill(selectedRollingMill)
        
        // Asignar valor
      }

      setLoadDataState(1);
    } 
    catch(err) 
    {
      setLoadDataState(-1);
      console.log(err);
    }
  }

  const onChangeMill = (a : any) => {
    console.log("Rolling Mill Changed to: " + a)
    setSelectedRollingMill(a);

    loadShiftsByRollingMill();
  }

  const dateCellRender = (value: Dayjs) => {
    return (
      <Row>
        
      </Row>
    );
  };

  const cellRender = (current: Dayjs, info: CellRenderInfo<Dayjs>) => {
    if (info.type === 'date') return dateCellRender(current);
    return info.originNode;
  };

  useEffect(() => {
    console.log("useEffect");
    loadRollingMills();
    loadShiftsByRollingMill();
  }, [])

  if (loading == 1) {
    return (
      <div>
        <Title title={HEADERS.SHIFT_SCHEDULE} />
        <ButtonUpdate />
        
        <FilterHeader>
          <SelectRollingMill onChange={onChangeMill} defaultValue={rollingMills[0].value}>
            {rollingMills.map((option) => (
              <Select key={option.value} value={option.value}>
                {option.label}
              </Select>
            ))}
          </SelectRollingMill>
        </FilterHeader>

        <CalendarioDeTurnos cellRender={cellRender} />
      </div>
    )
  } else if (loading == 0) {
    return (
      <Loading />
    )
  } else {
    return (
      <ErrorPage />
    )
  }
};

export default CalenadrioTurnos;
