import { Space } from 'antd';
import { COOKIE } from '../../../constants';
import UserMenuOptions from './UserMenuOptions';
import { DescriptionsItem, HeaderContainer } from './style';

function HeaderApp() {
    return (
        <HeaderContainer>
            <div>
                <DescriptionsItem label="UserName"> {localStorage.getItem(COOKIE.USERNAME)} </DescriptionsItem>
                <Space direction="vertical" size={16} >
                    <Space wrap size={16}>
                        <UserMenuOptions />
                    </Space>
                </Space>
            </div>
        </HeaderContainer>
    )
}

export default HeaderApp;