import { Button, Typography } from 'antd';
import styled from "styled-components";

export const ButtonStyled = styled(Button)`
  color: ${(props) => props.theme.color.white};
  background-color: ${(props) => props.theme.color.primary};
`

export const TypographicText = styled(Typography.Text)`
  color: ${(props) => props.theme.color.gray};
  font-style: italic;
`