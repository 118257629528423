import Sider from 'antd/es/layout/Sider'
import styled from 'styled-components'

export const SideBarContainer = styled(Sider)`
  height: calc(100vh - 52px);
  position: relative;

  aside {
    height: 100%;
  } 

  & .ant-menu-light .ant-menu-item-selected,
  & .ant-menu-light .ant-menu-item:not(.ant-menu-item-selected):active {
    color: white;
    background-color: ${(props) => props.theme.color.primary};
  }

  & .ant-menu-light .ant-menu-item:not(.ant-menu-item-selected),
  & .ant-menu-light .ant-menu-item:not(.ant-menu-item-selected):hover {
    color: ${(props) => props.theme.color.black};
  }
  
  & .ant-menu-light .ant-menu-submenu-selected >.ant-menu-submenu-title {
    color: ${(props) => props.theme.color.primary};
  }

  & .ant-layout-sider-trigger{
    background-color: ${(props) => props.theme.color.primary};
  }
`

export const Img = styled.img`
  max-Height: 60px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
`