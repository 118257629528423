import { useAtom } from "jotai";
import { loginAtom } from "../../../store/atoms";

const Logout = ({ onLogout, children }: { onLogout: Function; children: any }) => {
  
  const [login, setLoginAtom] = useAtom(loginAtom);
    const handleLogout = () => {
      setLoginAtom('false');
    };
    
    return (
      <div data-testid="user-logout" onClick={handleLogout}>
        {children}
      </div>
    );
  };
  
  Logout.defaultProps = {
    onLogout: () => {},
    children: "Logout",
  };
  
  export default Logout;
  