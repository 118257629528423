import { EditOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import Title from '../../../components/Title';
import { HEADERS, RESOURCES} from '../../../constants';

interface DataType {
    key: React.Key;
    centro: string;
    dato: number;
}

const columns: ColumnsType<DataType> = [
    {
        title: 'Centro',
        dataIndex: 'centro',
        key: 'centro',
        width: '200px',
    },
    {
        title: 'Dato',
        dataIndex: 'dato',
        key: 'dato'
    },
    {
        title: RESOURCES.ACTIONS,
        key: 'action',
        dataIndex: 'action',
        width: 100,
        render: () => <Button><EditOutlined />Editar</Button>
    }
];

const data: DataType[] = [
    {
        key: '1',
        centro: 'SMS',
        dato: 4
    },
    {
        key: '2',
        centro: 'Danieli',
        dato: 2
    },
    {
        key: '2',
        centro: 'Pletinas',
        dato: 6
    }
];

const ParametrizacionTemperaturas = () => {
    return (
        <div style={{ padding: '16px 16px' }}>
            <Title title={HEADERS.TEMPERATURE_PARAM} />
            <Table size='small' bordered dataSource={data} columns={columns} />
        </div>
    )
}

export default ParametrizacionTemperaturas;