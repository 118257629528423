import { Col, Descriptions, Select, Space } from 'antd'
import { Header } from 'antd/lib/layout/layout'
import styled from 'styled-components'

export const HeaderContainer = styled(Header)`
  background-color: ${({ theme }) => theme.color.primary};
  color: white;
  height: 52px;
  padding-inline: 0;
  line-height: normal;

  & > .ant-row {
    height: 52px;
  }

  & .ant-avatar {
    color: ${({ theme }) => theme.color.primary};
  }

  & > div {
    margin-top: 6px;
    width: 140px;
    float: right;
  }
`
export const LogoColConatiner = styled(Col)`
  flex: 1 1 auto;
  text-align: left;
  display: flex;
  align-items: center;
  height: 52px;
`

export const LogoConatiner = styled.div`
  margin-inline-start: 26px;
  height: 80%;

  img {
    max-height: 100%;
  }
`

export const UserMenuContainer = styled(Col)`
  flex: 0 0 40px;
  text-align: right;
  align-self: center;
  padding-inline-end: 50px;
  display: flex;
  align-items: center;
  height: 52px;
`

export const SpaceCenter = styled(Space)`
  padding-right: 2em;
`

export const SelectCenter = styled(Select)`
  min-width: 10em;
`

export const DescriptionsItem = styled(Descriptions.Item)`
  width: 100px;
  textAlign: left;
  color: ${({ theme }) => theme.color.primary};
`