import styled from "styled-components";

export const LoadingSpin = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    bottom: 50%;
    right: 50%;
    display: flex;
    justifyContent: center;
    alignItems: center;

    & .ant-spin-spinning {
        zoom: 3;
    }
`