import { RedoOutlined } from "@ant-design/icons";
import { Row } from 'antd';
import { ButtonStyled, TypographicText } from "./style";


const ButtonUpdate = (props: { fecha?: string; }) => {
  let today = new Date();

  let todayStr = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear() + "  " + today.getHours() +
    ":" + (today.getMinutes() > 10 ? today.getMinutes() : "0" + today.getMinutes());

  return (
    <div>
      <Row>
        <ButtonStyled>
          <RedoOutlined />
          Actualizar datos
        </ButtonStyled>
      </Row>
      <Row>
        <TypographicText>Última actualización: {props.fecha ?? todayStr}</TypographicText>
      </Row>
    </div>
  )
}

export default ButtonUpdate;