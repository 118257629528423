import React from 'react';
import { Route, Routes } from 'react-router-dom';
import RequireAuth from '../components/RequireAuth';
import Template from '../components/layout/Template';
import Permisos from '../pages/Administracion/Permisos';
import Roles from '../pages/Administracion/Roles';
import Usuarios from '../pages/Administracion/Usuarios';
import CalendarioElectrico from '../pages/Configuracion/Calendario electrico/CalendarioElectrico';
import CalendarioTurnos from '../pages/Configuracion/CalendarioTurnos/CalendarioTurnos';
import EstadoProducciones from '../pages/Configuracion/EstadoProducciones';
import MatrizTransicionCalidades from '../pages/Configuracion/Parametros maestros/MatrizTransicionCalidades';
import Palanquillas from '../pages/Configuracion/Parametros maestros/Palanquillas';
import ParametrizacionColada from '../pages/Configuracion/Parametros maestros/ParametrizacionColada';
import ParametrizacionTemperaturas from '../pages/Configuracion/Parametros maestros/ParametrizacionTemperaturas';
import ParametrizacionTrenes from '../pages/Configuracion/Parametros maestros/ParametrizacionTrenes';
import PrevisionConsumos from '../pages/Configuracion/PrevisionConsumos';
import StockPalanquillas from '../pages/Configuracion/StockPalanquillas';
import Home from '../pages/Home/Home';
import LoginPage from '../pages/Login/Login';
import CalendarioCiclos from '../pages/Planificacion/CalendarioCiclos/CalendarioCiclos';
import PrevisionCCYConsumoElectrico from '../pages/Planificacion/PrevisionCCYConsumoElectrico';
import ResumenMensual from '../pages/Planificacion/ResumenMensual';


const App: React.FC = () => {
  return (
    <Routes>
      <Route path='/' element={<LoginPage />} />
      <Route path="/test" element={<Template />}>
        <Route index element={RequireAuthPage(<Home />)}/>

        <Route path="CalendarioTurnos" element={RequireAuthPage(<CalendarioTurnos />)} />
        <Route path="CalendarioPrecioElectrico" element={RequireAuthPage(<CalendarioElectrico />)} />
        <Route path="StockPalanquillas" element={RequireAuthPage(<StockPalanquillas />)} />
        <Route path="EstadoProducciones" element={RequireAuthPage(<EstadoProducciones />)} />
        <Route path="PrevisionConsumos" element={RequireAuthPage(<PrevisionConsumos />)} />

        <Route path="Palanquillas" element={RequireAuthPage(<Palanquillas />)} />
        <Route path="MatrizTransicionCalidades" element={RequireAuthPage(<MatrizTransicionCalidades />)} />
        <Route path="HorasTrabajo" element={RequireAuthPage(<ParametrizacionTrenes />)} />
        <Route path="ParametrizacionColada" element={RequireAuthPage(<ParametrizacionColada />)} />
        <Route path="ParametrizacionTemperaturas" element={RequireAuthPage(<ParametrizacionTemperaturas />)} />

        <Route path="CalendarioCiclos" element={RequireAuthPage(<CalendarioCiclos />)} />
        <Route path="ResumenMensual" element={RequireAuthPage(<ResumenMensual />)} />
        <Route path="PrevisionCCyCE" element={RequireAuthPage(<PrevisionCCYConsumoElectrico />)} />

        <Route path="Usuarios" element={RequireAuthPage(<Usuarios />)} />
        <Route path="Roles" element={RequireAuthPage(<Roles />)} />
        <Route path="Permisos" element={RequireAuthPage(<Permisos />)} />
      </Route>
    </Routes>
  );
};

const RequireAuthPage = (element: any) => {
  return <RequireAuth>
    {element}
  </RequireAuth>
}
export default App;