import { DeleteOutlined, EyeOutlined, PlayCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { RESOURCES } from '../../../constants'

interface DataType {
    key: React.Key;
    fecha: string;
    parametros: string;
    descripcion: string;
}


const columns: ColumnsType<DataType> = [
    {
        title: 'Fecha',
        dataIndex: 'fecha',
        key: 'fecha',
        width: 100
    },
    {
        title: 'Parámetros de cálculo',
        dataIndex: 'parametros',
        key: 'parametros'
    },
    {
        title: 'Descripción',
        dataIndex: 'descripcion',
        key: 'descripcion'
    },
    {
        title: RESOURCES.ACTIONS,
        key: 'action',
        dataIndex: 'action',
        width: 330,
        render: () => <div><Button style={{ width: 100 }}><EyeOutlined />Ver</Button> <Button style={{ width: 100 }}><PlayCircleOutlined />Publicar</Button> <Button danger style={{ width: 100 }}><DeleteOutlined />Eliminar</Button></div>
    }
]

const data: DataType[] = [
    {
        key: '1',
        fecha: '20/04/2023',
        parametros: "Parametros usados",
        descripcion: 'descripcion'
    },
    {
        key: '2',
        fecha: '21/04/2023',
        parametros: "Parametros usados",
        descripcion: 'descripcion'
    },
    {
        key: '3',
        fecha: '22/04/2023',
        parametros: "Parametros usados",
        descripcion: 'descripcion'
    }
]


const GestionDePlanes = () => {
    return (
        <Table size='small' bordered dataSource={data} columns={columns} pagination={false} />
    )
}


export default GestionDePlanes;