import { EditOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import Title from '../../components/Title';
import { HEADERS, RESOURCES } from '../../constants';

interface DataType {
    key: React.Key;
    permisos: string;
    descripcion: string;
}

const columns: ColumnsType<DataType> = [
    {
        title: 'Permisos',
        dataIndex: 'permisos',
        key: 'permisos',
        width: '25%'
    },
    {
        title: 'Descripción',
        dataIndex: 'descripcion',
        key: 'descripcion'
    },
    {
        title: RESOURCES.ACTIONS,
        key: 'action',
        dataIndex: 'action',
        width: 100,
        render: () => <Button><EditOutlined />Editar</Button>
    }
];

const data: DataType[] = [
    {
        key: '1',
        permisos: 'Admin',
        descripcion: 'Acceso de administrador a todos los módulos.'
    },
    {
        key: '2',
        permisos: 'Lectura',
        descripcion: 'Visualización de los módulos.'
    },
    {
        key: '3',
        permisos: 'Escritura',
        descripcion: 'Edición de los datos de los módulos.'
    }
];


const Permisos = () => {
    return (
        <div style={{ padding: '16px 16px' }}>
            <Title title={HEADERS.PERMISSIONS} />
            <Table size='small' bordered dataSource={data} columns={columns} />
        </div>
    )
}

export default Permisos;