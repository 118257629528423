import { EditOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Row, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import React from 'react';
import Title from '../../../components/Title';
import ButtonUpdate from '../../../components/ButtonStyled';
import Searcher from '../../../components/Searcher';
import { HEADERS, RESOURCES } from '../../../constants';

interface DataType {
    key: React.Key;
    codigo: string;
    calidad: string;
    longitud: number;
    seccion: number;
    centroDefecto: string;
    centro: string[];
    descripcion: string;
}

const columns: ColumnsType<DataType> = [
    {
        title: 'Código',
        dataIndex: 'codigo',
        key: 'codigo',
        width: '300px'
    },
    {
        title: 'Calidad',
        dataIndex: 'calidad',
        key: 'calidad',
        width: '100px'
    },
    {
        title: 'Longitud',
        dataIndex: 'longitud',
        key: 'longitud',
        width: '100px'
    },
    {
        title: 'Sección',
        dataIndex: 'seccion',
        key: 'seccion',
        width: '100px'
    },
    {
        title: 'Centro por defecto',
        dataIndex: 'centroDefecto',
        key: 'centroDefecto',
        width: '200px'
    },
    {
        title: 'Centros de uso',
        dataIndex: 'centro',
        key: 'centro',
        width: '200px',
        render: (data: string[]) =>
            data.map((d) => {
                return (
                    <Row>{d}</Row>
                )
            })

    },
    {
        title: 'Descripción',
        dataIndex: 'descripcion',
        key: 'descripcion'
    },
    {
        title: RESOURCES.ACTIONS,
        key: 'action',
        dataIndex: 'action',
        width: 100,
        render: () => <div><Button><EditOutlined />Editar</Button></div>
    }
];

const data: DataType[] = [
    {
        key: '1',
        codigo: 'code001',
        calidad: 'calidad01',
        longitud: 10,
        seccion: 10,
        centroDefecto: 'SMS',
        centro: ['SMS'],
        descripcion: 'descripcion'
    },
    {
        key: '2',
        codigo: 'code002',
        calidad: 'calidad01',
        longitud: 20,
        seccion: 10,
        centroDefecto: 'SMS',
        centro: ['SMS'],
        descripcion: 'descripcion'
    },
    {
        key: '3',
        codigo: 'code003',
        calidad: 'calidad01',
        longitud: 30,
        seccion: 10,
        centroDefecto: 'SMS',
        centro: ['SMS'],
        descripcion: 'descripcion'
    },
    {
        key: '4',
        codigo: 'code004',
        calidad: 'calidad01',
        longitud: 10,
        seccion: 20,
        centroDefecto: 'SMS',
        centro: ['SMS'],
        descripcion: 'descripcion'
    },
    {
        key: '5',
        codigo: 'code005',
        calidad: 'calidad01',
        longitud: 10,
        seccion: 30,
        centroDefecto: 'SMS',
        centro: ['SMS', 'Danieli'],
        descripcion: 'descripcion'
    },
    {
        key: '6',
        codigo: 'code006',
        calidad: 'calidad02',
        longitud: 10,
        seccion: 10,
        centroDefecto: 'SMS',
        centro: ['SMS'],
        descripcion: 'descripcion'
    }
];

const Palanquillas = () => {
    return (
        <div style={{ padding: '16px 16px' }}>
            <Title title={HEADERS.BILLETS} />

            <Row>
                <Col span={4}>
                    <ButtonUpdate />
                </Col>
                <Col span={4} offset={14}>
                    <Searcher />
                </Col>
            </Row>
            <Divider />
            <Table columns={columns} dataSource={data} />
        </div>
    )
}

export default Palanquillas;