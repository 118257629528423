import { Button } from "antd"

export const theme = {
    color: {
      primary: '#c50f3c',
      header: '#004a88',
      lightGray: '#f5f5f5',
      darkGray: '#53565a',
      orange: '#e97300',
      green: '#6bc04b',
      yellow: '#ecaa00',
      purple: '#b150c5',
      lightBlue: '#dae9ff',
      red: '#c50f3c',
      black: '#000000',
      white: '#ffffff'
    }
  }