import { Spin } from "antd";
import { LoadingSpin } from "./style";

const Loading = () => {
    return (
        <LoadingSpin>
          <Spin />
        </LoadingSpin>
      )
}

export default Loading;