import { Card, Col, Row } from 'antd';
import Title from '../../components/Title';
import { RESOURCES, HEADERS } from '../../constants';
import ChartBar from './ChartBar';
import ChartColumn from './ChartColumn';
import ChartDualAxes from './ChartDualAxes';
import ChartPie from './ChartPie';

const Home = () => {
    return (
        <div style={{ padding: '16px 16px' }}>
            <Title title={HEADERS.HOME} />

            <Row gutter={[8, 8]} >
                <Col span={12} order={1}>
                    <Card title="Chart 1">
                        <ChartColumn />
                    </Card>
                </Col>
                <Col span={12} order={2}>
                    <Card title="Chart 2">
                        <ChartPie />
                    </Card>
                </Col>
                <Col span={12} order={3}>
                    <Card title="Chart 3">
                        <ChartDualAxes />
                    </Card>
                </Col>
                <Col span={12} order={4}>
                    <Card title="Chart 4">
                        <ChartBar />
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default Home;