import { SearchOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker } from "antd";
import { DatePickerStyle } from "./style";

const DatePickerFilter = () => {
    return (
        <DatePickerStyle>
            <Col span={10}>
                <DatePicker />
            </Col>
            <Col span={10}>
                <DatePicker />
            </Col>
            <Col span={4}>
                <Button type="primary"><SearchOutlined /></Button>
            </Col>
        </DatePickerStyle>
    )
}

export default DatePickerFilter;