import { Bar } from '@ant-design/charts';

const ChartBar = () => {
  const data = [
    { year: '2020', sales: 100 },
    { year: '2021', sales: 200 },
    { year: '2022', sales: 150 },
    { year: '2023', sales: 400 },
    { year: '2024', sales: 300 },
  ];

  const config = {
    data,
    xField: 'year',
    yField: 'sales',
    color: '#2e8b57',
  };

  return (

    <Bar {...config} />

  );
};

export default ChartBar;