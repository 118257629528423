import { EditOutlined } from '@ant-design/icons';
import { Button, Table, Tabs } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import TabPane from 'antd/es/tabs/TabPane';
import React from 'react';
import Title from '../../../components/Title';
import { RESOURCES, HEADERS } from '../../../constants';
import TablaTurnosMantenimiento from './TablaTurnosMantenimiento';

interface DataType {
    key: React.Key;
    colada: string;
    propiedad: string;
    unidad: string;
    valor: number;
}

const columns: ColumnsType<DataType> = [
    {
        title: 'Propiedad',
        dataIndex: 'propiedad',
        key: 'propiedad'
    },
    {
        title: 'Unidad de medida',
        dataIndex: 'unidad',
        key: 'unidad'
    },
    {
        title: 'Valor',
        dataIndex: 'valor',
        key: 'valor'
    },
    {
        title: RESOURCES.ACTIONS,
        key: 'action',
        dataIndex: 'action',
        width: 100,
        render: () => <Button><EditOutlined />Editar</Button>
    }
];

const data: DataType[] = [
    {
        key: '2',
        colada: RESOURCES.CC1,
        propiedad: 'Productividad (alta potencia)',
        unidad: RESOURCES.TN_H,
        valor: 163
    },
    {
        key: '4',
        colada: RESOURCES.CC1,
        propiedad: 'Productividad (baja potencia)',
        unidad: RESOURCES.TN_H,
        valor: 138
    },
    {
        key: '5',
        colada: RESOURCES.CC1,
        propiedad: 'Productividad (super baja potencia)',
        unidad: RESOURCES.TN_H,
        valor: 125
    },
    {
        key: '6',
        colada: RESOURCES.CC1,
        propiedad: 'Potencia (alta)',
        unidad: RESOURCES.MW,
        valor: 90
    },
    {
        key: '8',
        colada: RESOURCES.CC1,
        propiedad: 'Potencia (baja)',
        unidad: RESOURCES.MW,
        valor: 70
    },
    {
        key: '9',
        colada: RESOURCES.CC1,
        propiedad: 'Potencia (super baja)',
        unidad: RESOURCES.MW,
        valor: 60
    },
    {
        key: '1',
        colada: RESOURCES.CC1,
        propiedad: 'Tiempo de uso del tundish',
        unidad: RESOURCES.HOURS,
        valor: 54
    },
    {
        key: '10',
        colada: RESOURCES.CC1,
        propiedad: 'Tiempo de cambio entre tundish',
        unidad: RESOURCES.MINUTES,
        valor: 60
    },
    {
        key: '11',
        colada: RESOURCES.CC1,
        propiedad: 'Tiempo de mantenimiento mínimo',
        unidad: RESOURCES.HOURS,
        valor: 8
    },
    {
        key: '12',
        colada: RESOURCES.CC1,
        propiedad: 'Tiempo de mantenimiento máximo',
        unidad: RESOURCES.HOURS,
        valor: 96
    },
    {
        key: '13',
        colada: RESOURCES.CC1,
        propiedad: 'Horas de trabajo mínimas',
        unidad: RESOURCES.HOURS,
        valor: 96
    },
    {
        key: '14',
        colada: RESOURCES.CC1,
        propiedad: 'Horas de trabajo máximas',
        unidad: RESOURCES.HOURS,
        valor: 96
    }
];

const ParametrizacionColada = () => {
    return (
        <div style={{ padding: '16px 16px' }}>
            <Title title={HEADERS.CC_PARAM} />
            <Tabs tabPosition='top' type="card">
                <TabPane tab={RESOURCES.CC1} key="1">
                    <Table size='small' bordered columns={columns} dataSource={data} pagination={false} />
                    <TablaTurnosMantenimiento />
                </TabPane>
                <TabPane tab={RESOURCES.CC2} key="2">
                    <Table size='small' bordered columns={columns} dataSource={data} pagination={false} />
                    <TablaTurnosMantenimiento />
                </TabPane>
            </Tabs>
        </div>
    )
}

export default ParametrizacionColada;