
import { Line } from '@ant-design/plots';

const ChartLine = () => {
  const current = new Date();

  const data = [
    {
      hour: current.getDate() + '/' + current.getMonth() + '\n0h',
      value: 3,
    },
    {
      hour: 1,
      value: 4,
    },
    {
      hour: 2,
      value: 3.5,
    },
    {
      hour: 3,
      value: 5,
    },
    {
      hour: 4,
      value: 4.9,
    },
    {
      hour: 5,
      value: 6,
    },
    {
      hour: 6,
      value: 7,
    },
    {
      hour: 7,
      value: 9,
    },
    {
      hour: 8,
      value: 13,
    },
    {
      hour: 9,
      value: 15,
    },
    {
      hour: 10,
      value: 23,
    },
    {
      hour: 11,
      value: 28,
    },
    {
      hour: 12,
      value: 33,
    },
    {
      hour: 13,
      value: 20,
    },
    {
      hour: 14,
      value: 13,
    },
    {
      hour: 15,
      value: 13,
    },
    {
      hour: 16,
      value: 13,
    },
    {
      hour: 17,
      value: 16,
    },
    {
      hour: 18,
      value: 16,
    },
    {
      hour: 19,
      value: 17,
    },
    {
      hour: 20,
      value: 15,
    },
    {
      hour: 21,
      value: 13,
    },
    {
      hour: 22,
      value: 13,
    },
    {
      hour: 23,
      value: 11,
    },
  ];
  const config = {
    data,
    xField: 'hour',
    yField: 'value',
    label: {},
    point: {
      size: 5,
      shape: 'diamond',
      style: {
        fill: 'white',
        stroke: '#5B8FF9',
        lineWidth: 2,
      },
    },
    tooltip: {
      showMarkers: false,
    },
    state: {
      active: {
        style: {
          shadowBlur: 4,
          stroke: '#000',
          fill: 'red',
        },
      },
    },
    interactions: [
      {
        type: 'marker-active',
      },
    ],
  };
  return <Line {...config} style={{ height: '450px' }} />;
}

export default ChartLine;