import { Tabs } from "antd";
import TabPane from 'antd/es/tabs/TabPane';
import Title from "../../../components/Title";
import { HEADERS } from '../../../constants';
import GestionDePlanes from "./GestionDePlanes";
import TablaCiclos from "./TablaCiclos";


const CalendarioCiclos = () => {
    return (
        <div style={{ padding: '16px 16px' }}>
            <Title title={HEADERS.CYCLE_SCHEDULE} />
            <Tabs type='card'>
                <TabPane tab="Versión actual" key={1} >
                    <TablaCiclos />
                </TabPane>
                <TabPane tab="Gestión de planes" key={2}>
                    <GestionDePlanes />
                </TabPane>
            </Tabs>
        </div>
    )
}

export default CalendarioCiclos;