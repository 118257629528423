import { Col, Divider, Row, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import React from 'react';
import Title from '../../components/Title';
import Searcher from '../../components/Searcher';
import { HEADERS } from '../../constants';

interface DataType {
    key: React.Key;
    centro: string;
    codigo: string;
    loteColada: string;
    quantity: number;
    fechaRecepcion: string;
}

const columns: ColumnsType<DataType> = [
    {
        title: 'Centro',
        dataIndex: 'centro',
        key: 'centro'
    },
    {
        title: 'Código',
        dataIndex: 'codigo',
        key: 'codigo'
    },
    {
        title: 'Lote / Num. Colada',
        dataIndex: 'loteColada',
        key: 'loteColada'
    },
    {
        title: 'Cantidad',
        dataIndex: 'quantity',
        key: 'quantity'
    },
    {
        title: 'Fecha',
        dataIndex: 'fechaRecepcion',
        key: 'fechaRecepcion'
    }
];

const data: DataType[] = [
    {
        key: '1',
        centro: 'SMS',
        codigo: 'Codigo01',
        loteColada: 'Lote01',
        quantity: 52,
        fechaRecepcion: '22/05/2022'
    },
    {
        key: '2',
        centro: 'SMS',
        codigo: 'Codigo02',
        loteColada: 'Lote01',
        quantity: 18,
        fechaRecepcion: '22/05/2022'
    },
    {
        key: '3',
        centro: 'SMS',
        codigo: 'Codigo03',
        loteColada: 'Lote01',
        quantity: 82,
        fechaRecepcion: '22/05/2022'
    },
    {
        key: '4',
        centro: 'SMS',
        codigo: 'Codigo04',
        loteColada: 'Lote01',
        quantity: 86,
        fechaRecepcion: '22/05/2022'
    },
    {
        key: '5',
        centro: 'SMS',
        codigo: 'Codigo05',
        loteColada: 'Lote01',
        quantity: 90,
        fechaRecepcion: '22/05/2022'
    },
    {
        key: '6',
        centro: 'SMS',
        codigo: 'Codigo06',
        loteColada: 'Lote01',
        quantity: 59,
        fechaRecepcion: '23/05/2022'
    },
    {
        key: '7',
        centro: 'SMS',
        codigo: 'Codigo07',
        loteColada: 'Lote01',
        quantity: 78,
        fechaRecepcion: '23/05/2022'
    },
    {
        key: '8',
        centro: 'SMS',
        codigo: 'Codigo08',
        loteColada: 'Lote01',
        quantity: 89,
        fechaRecepcion: '23/05/2022'
    },
    {
        key: '9',
        centro: 'SMS',
        codigo: 'Codigo09',
        loteColada: 'Lote01',
        quantity: 80,
        fechaRecepcion: '23/05/2022'
    },
    {
        key: '10',
        centro: 'SMS',
        codigo: 'Codigo10',
        loteColada: 'Lote01',
        quantity: 90,
        fechaRecepcion: '23/05/2022'
    },
    {
        key: '11',
        centro: 'SMS',
        codigo: 'Codigo11',
        loteColada: 'Lote01',
        quantity: 8,
        fechaRecepcion: '24/05/2022'
    },
    {
        key: '12',
        centro: 'SMS',
        codigo: 'Codigo12',
        loteColada: 'Lote01',
        quantity: 8,
        fechaRecepcion: '24/05/2022'
    }

];


const EstadoProducciones = () => {
    return (
        <div style={{ padding: '16px 16px' }}>
            <Title title={HEADERS.PRODUCTION_STATE} />
            <Row>
                <Col span={4} offset={18}>
                    <Searcher />
                </Col>
            </Row>
            <Divider />
            <Table size='small' bordered columns={columns} dataSource={data} />
        </div>
    )
}

export default EstadoProducciones;