import { EditOutlined } from '@ant-design/icons';
import { Button, Table, Tabs } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import TabPane from 'antd/es/tabs/TabPane';
import React from 'react';
import Title from '../../../components/Title';
import { RESOURCES, HEADERS } from '../../../constants';


interface DataTypeLaminaciones {
    key: React.Key;
    parametro: string;
    valor: number;
}

const columns: ColumnsType<DataTypeLaminaciones> = [
    {
        title: 'Parámetro',
        dataIndex: 'parametro',
        key: 'parametro'
    },
    {
        title: 'Valor',
        dataIndex: 'valor',
        key: 'valor'
    },
    {
        title: RESOURCES.ACTIONS,
        key: 'action',
        dataIndex: 'action',
        width: 100,
        render: () => <Button><EditOutlined />Editar</Button>
    }
]


const data: DataTypeLaminaciones[] = [
    {
        key: '1',
        parametro: 'Stock mínimo',
        valor: 15000
    },
    {
        key: '2',
        parametro: 'Stock máximo',
        valor: 30000
    },
    {
        key: '3',
        parametro: 'Stock objetivo',
        valor: 28000
    },
    {
        key: '4',
        parametro: 'Número de paros máximos / mes',
        valor: 1
    }
]

const data2: DataTypeLaminaciones[] = [
    {
        key: '1',
        parametro: 'Stock mínimo',
        valor: 18000
    },
    {
        key: '2',
        parametro: 'Stock máximo',
        valor: 32000
    },
    {
        key: '3',
        parametro: 'Stock objetivo',
        valor: 26000
    },
    {
        key: '4',
        parametro: 'Número de paros máximos / mes',
        valor: 2
    }
]

const data3: DataTypeLaminaciones[] = [
    {
        key: '1',
        parametro: 'Stock mínimo',
        valor: 12000
    },
    {
        key: '2',
        parametro: 'Stock máximo',
        valor: 24000
    },
    {
        key: '3',
        parametro: 'Stock objetivo',
        valor: 20000
    },
    {
        key: '4',
        parametro: 'Número de paros máximos / mes',
        valor: 3
    }
]

const ParametrizacionTrenes = () => {
    return (
        <div style={{ padding: '16px 16px' }}>
            <Title title={HEADERS.MILL_PARAM} />
            <Tabs tabPosition='top' type="card">
                <TabPane tab="SMS" key={1}>
                    <Table size='small' bordered columns={columns} dataSource={data} pagination={false} />
                </TabPane>
                <TabPane tab="Danieli" key={2}>
                    <Table size='small' bordered columns={columns} dataSource={data2} pagination={false} />
                </TabPane>
                <TabPane tab="Pletinas" key={3}>
                    <Table size='small' bordered columns={columns} dataSource={data3} pagination={false} />
                </TabPane>
            </Tabs>
        </div>
    )
}

export default ParametrizacionTrenes;