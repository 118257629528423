import { EditOutlined } from "@ant-design/icons";
import { Button, Col, Row, Table, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import DatePickerFilter from "../../../components/DatePickerFilter";
import { RESOURCES } from "../../../constants"

interface HorasStocks {
    centro: string;
    valor: number
}

// Colada > Centro / Valor
interface CCValue {
    colada: number;
    centro: string;
    valor: number;
}

// Centro / Valor
interface CenterValue {
    centro: string;
    valor: number;
}

interface Produccion {
    horas: HorasStocks[];
    datosProduccion: CCValue[];
}

interface Consumo {
    horas: HorasStocks[];
    datosConsumo: CenterValue[];
}

interface DataTypeProduccion {
    key: React.Key;
    fecha: string;
    produccion: Produccion;
    consumo: Consumo;
    stocks: CenterValue[]
    productividad: number;
}

const columnsProduccion: ColumnsType<DataTypeProduccion> = [
    {
        title: 'Fecha',
        dataIndex: 'fecha',
        key: 'fecha',
        fixed: 'left',
        width: 100,
    },
    {
        title: 'Producción',
        children: [
            {
                title: 'Horas CC1',
                width: 80,
                dataIndex: 'produccion',
                render: (produccion: Produccion) => {
                    let idx = produccion.horas.findIndex(f => f.centro == RESOURCES.CC1)
                    if (idx < 0) return (<div></div>)
                    return (
                        <div>{produccion.horas[idx].valor}</div>
                    )
                }
            },
            {
                title: 'Horas CC2',
                width: 80,
                dataIndex: 'produccion',
                render: (produccion: Produccion) => {
                    let idx = produccion.horas.findIndex(f => f.centro == RESOURCES.CC2)
                    if (idx < 0) return (<div></div>)
                    return (
                        <div>{produccion.horas[idx].valor}</div>
                    )
                }
            },
            {
                title: RESOURCES.CC1,
                children: [
                    {
                        title: "Total",
                        width: 80,
                        dataIndex: 'produccion',
                        render: (produccion: Produccion) => {
                            let idx = produccion.datosProduccion.findIndex(f => f.centro == "Total" && f.colada == 1);
                            if (idx < 0) return (<div></div>)
                            return (
                                <div style={{ background: '#bfffbe', padding: 14, margin: -8, height: 50, width: 80, textAlign: 'center' }}>
                                    <Typography.Text> {produccion.datosProduccion[idx].valor}</Typography.Text>
                                </div>
                            )
                        }
                    },
                    {
                        title: "Danieli",
                        width: 80,
                        dataIndex: 'produccion',
                        render: (produccion: Produccion) => {
                            let idx = produccion.datosProduccion.findIndex(f => f.centro == "Danieli" && f.colada == 1);
                            if (idx < 0) return (<div></div>)
                            return (
                                <div>{produccion.datosProduccion[idx].valor}</div>
                            )
                        }
                    },
                    {
                        title: "Pomini",
                        width: 80,
                        dataIndex: 'produccion',
                        render: (produccion: Produccion) => {
                            let idx = produccion.datosProduccion.findIndex(f => f.centro == "Pomini" && f.colada == 1);
                            if (idx < 0) return (<div></div>)
                            return (
                                <div>{produccion.datosProduccion[idx].valor}</div>
                            )
                        }
                    },
                    {
                        title: "Venta",
                        width: 80,
                        dataIndex: 'produccion',
                        render: (produccion: Produccion) => {
                            let idx = produccion.datosProduccion.findIndex(f => f.centro == "Venta" && f.colada == 1);
                            if (idx < 0) return (<div></div>)
                            return (
                                <div>{produccion.datosProduccion[idx].valor}</div>
                            )
                        }
                    },
                    {
                        title: "SMS",
                        width: 80,
                        dataIndex: 'produccion',
                        render: (produccion: Produccion) => {
                            let idx = produccion.datosProduccion.findIndex(f => f.centro == "SMS" && f.colada == 1);
                            if (idx < 0) return (<div></div>)
                            return (
                                <div>{produccion.datosProduccion[idx].valor}</div>
                            )
                        }
                    }
                ]
            },
            {
                title: RESOURCES.CC2,
                children: [
                    {
                        title: "Total",
                        width: 80,
                        dataIndex: 'produccion',
                        render: (produccion: Produccion) => {
                            let idx = produccion.datosProduccion.findIndex(f => f.centro == "Total" && f.colada == 2);
                            if (idx < 0) return (<div></div>)
                            return (
                                <div style={{ background: '#bfffbe', padding: 14, margin: -8, height: 50, width: 80, textAlign: 'center' }}>
                                    <Typography.Text> {produccion.datosProduccion[idx].valor}</Typography.Text>
                                </div>
                            )
                        }
                    },
                    {
                        title: "Danieli",
                        width: 80,
                        dataIndex: 'produccion',
                        render: (produccion: Produccion) => {
                            let idx = produccion.datosProduccion.findIndex(f => f.centro == "Danieli" && f.colada == 2);
                            if (idx < 0) return (<div></div>)
                            return (
                                <div>{produccion.datosProduccion[idx].valor}</div>
                            )
                        }
                    },
                    {
                        title: "Pomini",
                        width: 80,
                        dataIndex: 'produccion',
                        render: (produccion: Produccion) => {
                            let idx = produccion.datosProduccion.findIndex(f => f.centro == "Pomini" && f.colada == 2);
                            if (idx < 0) return (<div></div>)
                            return (
                                <div>{produccion.datosProduccion[idx].valor}</div>
                            )
                        }
                    },
                    {
                        title: "SMS",
                        width: 80,
                        dataIndex: 'produccion',
                        render: (produccion: Produccion) => {
                            let idx = produccion.datosProduccion.findIndex(f => f.centro == "SMS" && f.colada == 2);
                            if (idx < 0) return (<div></div>)
                            return (
                                <div>{produccion.datosProduccion[idx].valor}</div>
                            )
                        }
                    }
                ]
            }
        ]
    },
    {
        title: 'Consumo',
        children: [
            {
                title: 'Horas Danieli',
                dataIndex: 'consumo',
                width: 80,
                render: (produccion: Consumo) => {
                    let idx = produccion.horas.findIndex(f => f.centro == "Danieli")
                    if (idx < 0) return (<div></div>)
                    return (
                        <div>{produccion.horas[idx].valor}</div>
                    )
                }
            },
            {
                title: 'Horas Pomini',
                dataIndex: 'consumo',
                width: 80,
                render: (produccion: Consumo) => {
                    let idx = produccion.horas.findIndex(f => f.centro == "Pomini")
                    if (idx < 0) return (<div></div>)
                    return (
                        <div>{produccion.horas[idx].valor}</div>
                    )
                }
            },
            {
                title: 'Horas SMS',
                dataIndex: 'consumo',
                width: 80,
                render: (produccion: Consumo) => {
                    let idx = produccion.horas.findIndex(f => f.centro == "SMS")
                    if (idx < 0) return (<div></div>)
                    return (
                        <div>{produccion.horas[idx].valor}</div>
                    )
                }
            },
            {
                title: 'Consumos',
                children: [
                    {
                        title: 'Danieli',
                        width: 80,
                        dataIndex: 'consumo',
                        render: (produccion: Consumo) => {
                            let idx = produccion.datosConsumo.findIndex(f => f.centro == "Danieli");
                            if (idx < 0) return (<div></div>)
                            return (
                                <div>{produccion.datosConsumo[idx].valor}</div>
                            )
                        }
                    },
                    {
                        title: 'Pomini',
                        width: 80,
                        dataIndex: 'consumo',
                        render: (produccion: Consumo) => {
                            let idx = produccion.datosConsumo.findIndex(f => f.centro == "Pomini");
                            if (idx < 0) return (<div></div>)
                            return (
                                <div>{produccion.datosConsumo[idx].valor}</div>
                            )
                        }
                    },
                    {
                        title: 'SMS',
                        width: 80,
                        dataIndex: 'consumo',
                        render: (produccion: Consumo) => {
                            let idx = produccion.datosConsumo.findIndex(f => f.centro == "SMS");
                            if (idx < 0) return (<div></div>)
                            return (
                                <div>{produccion.datosConsumo[idx].valor}</div>
                            )
                        }
                    }
                ]
            }
        ]
    },
    {
        title: 'Stocks',
        children: [
            {
                title: 'Danieli',
                width: 80,
                dataIndex: 'stocks',
                render: (data: CenterValue[]) => {
                    let idx = data.findIndex(f => f.centro == "Danieli")
                    if (idx < 0) return (<div></div>)
                    return (
                        <div>
                            {data[idx].valor}
                        </div>
                    )
                }
            },
            {
                title: 'Pomini',
                width: 80,
                dataIndex: 'stocks',
                render: (data: CenterValue[]) => {
                    let idx = data.findIndex(f => f.centro == "Pomini")
                    if (idx < 0) return (<div></div>)
                    return (
                        <div>
                            {data[idx].valor}
                        </div>
                    )
                }
            },
            {
                title: 'SMS',
                width: 80,
                dataIndex: 'stocks',
                render: (data: CenterValue[]) => {
                    let idx = data.findIndex(f => f.centro == "SMS")
                    if (idx < 0) return (<div></div>)
                    return (
                        <div>
                            {data[idx].valor}
                        </div>
                    )
                }
            },
            {
                title: 'Total',
                width: 80,
                dataIndex: 'stocks',
                render: (data: CenterValue[]) => {
                    let idx = data.findIndex(f => f.centro == "Total")
                    if (idx < 0) return (<div></div>)
                    return (
                        <div style={{ background: '#bfffbe', padding: 14, margin: -8, height: 50, width: 80, textAlign: 'center' }}>
                            <Typography.Text>
                                {data[idx].valor}
                            </Typography.Text>
                        </div>
                    )
                }
            }
        ]
    },
    {
        title: 'Productividad (' + RESOURCES.TN_H + ')',
        width: 80,
        dataIndex: 'productividad'
    },
    {
        title: RESOURCES.ACTIONS,
        key: 'action',
        dataIndex: 'action',
        fixed: 'right',
        width: 100,
        render: () => <Button><EditOutlined />Editar</Button>
    }
];



const dataProduccion: DataTypeProduccion[] = [
    {
        key: '1',
        fecha: '20/04/2023',
        produccion: {
            horas: [
                {
                    centro: RESOURCES.CC1,
                    valor: 308
                },
                {
                    centro: RESOURCES.CC2,
                    valor: 309
                },
            ],
            datosProduccion: [
                {
                    centro: 'Total',
                    colada: 1,
                    valor: 46395
                },
                {
                    centro: 'Danieli',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'Pomini',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'Venta',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'SMS',
                    colada: 1,
                    valor: 46395
                },
                {
                    centro: 'Total',
                    colada: 2,
                    valor: 43638
                },
                {
                    centro: 'Danieli',
                    colada: 2,
                    valor: 30615
                },
                {
                    centro: 'Pomini',
                    colada: 2,
                    valor: 13023
                },
                {
                    centro: 'SMS',
                    colada: 2,
                    valor: 0
                }
            ],
        },
        consumo: {
            horas: [
                {
                    centro: 'Danieli',
                    valor: 344
                },
                {
                    centro: 'Pomini',
                    valor: 192
                },
                {
                    centro: 'SMS',
                    valor: 417
                }
            ],
            datosConsumo: [
                {
                    centro: 'Danieli',
                    valor: 26639
                },
                {
                    centro: 'Pomini',
                    valor: 13518
                },
                {
                    centro: 'SMS',
                    valor: 51058
                }
            ]
        },
        stocks: [
            {
                centro: 'Danieli',
                valor: 100
            },
            {
                centro: 'Pomini',
                valor: 100
            },
            {
                centro: 'SMS',
                valor: 100
            },
            {
                centro: 'Total',
                valor: 300
            }
        ],
        productividad: 138
    },
    {
        key: '1',
        fecha: '20/04/2023',
        produccion: {
            horas: [
                {
                    centro: RESOURCES.CC1,
                    valor: 308
                },
                {
                    centro: RESOURCES.CC2,
                    valor: 309
                },
            ],
            datosProduccion: [
                {
                    centro: 'Total',
                    colada: 1,
                    valor: 46395
                },
                {
                    centro: 'Danieli',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'Pomini',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'Venta',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'SMS',
                    colada: 1,
                    valor: 46395
                },
                {
                    centro: 'Total',
                    colada: 2,
                    valor: 43638
                },
                {
                    centro: 'Danieli',
                    colada: 2,
                    valor: 30615
                },
                {
                    centro: 'Pomini',
                    colada: 2,
                    valor: 13023
                },
                {
                    centro: 'SMS',
                    colada: 2,
                    valor: 0
                }
            ],
        },
        consumo: {
            horas: [
                {
                    centro: 'Danieli',
                    valor: 344
                },
                {
                    centro: 'Pomini',
                    valor: 192
                },
                {
                    centro: 'SMS',
                    valor: 417
                }
            ],
            datosConsumo: [
                {
                    centro: 'Danieli',
                    valor: 26639
                },
                {
                    centro: 'Pomini',
                    valor: 13518
                },
                {
                    centro: 'SMS',
                    valor: 51058
                }
            ]
        },
        stocks: [
            {
                centro: 'Danieli',
                valor: 100
            },
            {
                centro: 'Pomini',
                valor: 100
            },
            {
                centro: 'SMS',
                valor: 100
            },
            {
                centro: 'Total',
                valor: 300
            }
        ],
        productividad: 138
    },
    {
        key: '1',
        fecha: '20/04/2023',
        produccion: {
            horas: [
                {
                    centro: RESOURCES.CC1,
                    valor: 308
                },
                {
                    centro: RESOURCES.CC2,
                    valor: 309
                },
            ],
            datosProduccion: [
                {
                    centro: 'Total',
                    colada: 1,
                    valor: 46395
                },
                {
                    centro: 'Danieli',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'Pomini',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'Venta',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'SMS',
                    colada: 1,
                    valor: 46395
                },
                {
                    centro: 'Total',
                    colada: 2,
                    valor: 43638
                },
                {
                    centro: 'Danieli',
                    colada: 2,
                    valor: 30615
                },
                {
                    centro: 'Pomini',
                    colada: 2,
                    valor: 13023
                },
                {
                    centro: 'SMS',
                    colada: 2,
                    valor: 0
                }
            ],
        },
        consumo: {
            horas: [
                {
                    centro: 'Danieli',
                    valor: 344
                },
                {
                    centro: 'Pomini',
                    valor: 192
                },
                {
                    centro: 'SMS',
                    valor: 417
                }
            ],
            datosConsumo: [
                {
                    centro: 'Danieli',
                    valor: 26639
                },
                {
                    centro: 'Pomini',
                    valor: 13518
                },
                {
                    centro: 'SMS',
                    valor: 51058
                }
            ]
        },
        stocks: [
            {
                centro: 'Danieli',
                valor: 100
            },
            {
                centro: 'Pomini',
                valor: 100
            },
            {
                centro: 'SMS',
                valor: 100
            },
            {
                centro: 'Total',
                valor: 300
            }
        ],
        productividad: 138
    },
    {
        key: '1',
        fecha: '20/04/2023',
        produccion: {
            horas: [
                {
                    centro: RESOURCES.CC1,
                    valor: 308
                },
                {
                    centro: RESOURCES.CC2,
                    valor: 309
                },
            ],
            datosProduccion: [
                {
                    centro: 'Total',
                    colada: 1,
                    valor: 46395
                },
                {
                    centro: 'Danieli',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'Pomini',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'Venta',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'SMS',
                    colada: 1,
                    valor: 46395
                },
                {
                    centro: 'Total',
                    colada: 2,
                    valor: 43638
                },
                {
                    centro: 'Danieli',
                    colada: 2,
                    valor: 30615
                },
                {
                    centro: 'Pomini',
                    colada: 2,
                    valor: 13023
                },
                {
                    centro: 'SMS',
                    colada: 2,
                    valor: 0
                }
            ],
        },
        consumo: {
            horas: [
                {
                    centro: 'Danieli',
                    valor: 344
                },
                {
                    centro: 'Pomini',
                    valor: 192
                },
                {
                    centro: 'SMS',
                    valor: 417
                }
            ],
            datosConsumo: [
                {
                    centro: 'Danieli',
                    valor: 26639
                },
                {
                    centro: 'Pomini',
                    valor: 13518
                },
                {
                    centro: 'SMS',
                    valor: 51058
                }
            ]
        },
        stocks: [
            {
                centro: 'Danieli',
                valor: 100
            },
            {
                centro: 'Pomini',
                valor: 100
            },
            {
                centro: 'SMS',
                valor: 100
            },
            {
                centro: 'Total',
                valor: 300
            }
        ],
        productividad: 138
    },
    {
        key: '1',
        fecha: '20/04/2023',
        produccion: {
            horas: [
                {
                    centro: RESOURCES.CC1,
                    valor: 308
                },
                {
                    centro: RESOURCES.CC2,
                    valor: 309
                },
            ],
            datosProduccion: [
                {
                    centro: 'Total',
                    colada: 1,
                    valor: 46395
                },
                {
                    centro: 'Danieli',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'Pomini',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'Venta',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'SMS',
                    colada: 1,
                    valor: 46395
                },
                {
                    centro: 'Total',
                    colada: 2,
                    valor: 43638
                },
                {
                    centro: 'Danieli',
                    colada: 2,
                    valor: 30615
                },
                {
                    centro: 'Pomini',
                    colada: 2,
                    valor: 13023
                },
                {
                    centro: 'SMS',
                    colada: 2,
                    valor: 0
                }
            ],
        },
        consumo: {
            horas: [
                {
                    centro: 'Danieli',
                    valor: 344
                },
                {
                    centro: 'Pomini',
                    valor: 192
                },
                {
                    centro: 'SMS',
                    valor: 417
                }
            ],
            datosConsumo: [
                {
                    centro: 'Danieli',
                    valor: 26639
                },
                {
                    centro: 'Pomini',
                    valor: 13518
                },
                {
                    centro: 'SMS',
                    valor: 51058
                }
            ]
        },
        stocks: [
            {
                centro: 'Danieli',
                valor: 100
            },
            {
                centro: 'Pomini',
                valor: 100
            },
            {
                centro: 'SMS',
                valor: 100
            },
            {
                centro: 'Total',
                valor: 300
            }
        ],
        productividad: 138
    },
    {
        key: '1',
        fecha: '20/04/2023',
        produccion: {
            horas: [
                {
                    centro: RESOURCES.CC1,
                    valor: 308
                },
                {
                    centro: RESOURCES.CC2,
                    valor: 309
                },
            ],
            datosProduccion: [
                {
                    centro: 'Total',
                    colada: 1,
                    valor: 46395
                },
                {
                    centro: 'Danieli',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'Pomini',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'Venta',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'SMS',
                    colada: 1,
                    valor: 46395
                },
                {
                    centro: 'Total',
                    colada: 2,
                    valor: 43638
                },
                {
                    centro: 'Danieli',
                    colada: 2,
                    valor: 30615
                },
                {
                    centro: 'Pomini',
                    colada: 2,
                    valor: 13023
                },
                {
                    centro: 'SMS',
                    colada: 2,
                    valor: 0
                }
            ],
        },
        consumo: {
            horas: [
                {
                    centro: 'Danieli',
                    valor: 344
                },
                {
                    centro: 'Pomini',
                    valor: 192
                },
                {
                    centro: 'SMS',
                    valor: 417
                }
            ],
            datosConsumo: [
                {
                    centro: 'Danieli',
                    valor: 26639
                },
                {
                    centro: 'Pomini',
                    valor: 13518
                },
                {
                    centro: 'SMS',
                    valor: 51058
                }
            ]
        },
        stocks: [
            {
                centro: 'Danieli',
                valor: 100
            },
            {
                centro: 'Pomini',
                valor: 100
            },
            {
                centro: 'SMS',
                valor: 100
            },
            {
                centro: 'Total',
                valor: 300
            }
        ],
        productividad: 138
    },
    {
        key: '1',
        fecha: '20/04/2023',
        produccion: {
            horas: [
                {
                    centro: RESOURCES.CC1,
                    valor: 308
                },
                {
                    centro: RESOURCES.CC2,
                    valor: 309
                },
            ],
            datosProduccion: [
                {
                    centro: 'Total',
                    colada: 1,
                    valor: 46395
                },
                {
                    centro: 'Danieli',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'Pomini',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'Venta',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'SMS',
                    colada: 1,
                    valor: 46395
                },
                {
                    centro: 'Total',
                    colada: 2,
                    valor: 43638
                },
                {
                    centro: 'Danieli',
                    colada: 2,
                    valor: 30615
                },
                {
                    centro: 'Pomini',
                    colada: 2,
                    valor: 13023
                },
                {
                    centro: 'SMS',
                    colada: 2,
                    valor: 0
                }
            ],
        },
        consumo: {
            horas: [
                {
                    centro: 'Danieli',
                    valor: 344
                },
                {
                    centro: 'Pomini',
                    valor: 192
                },
                {
                    centro: 'SMS',
                    valor: 417
                }
            ],
            datosConsumo: [
                {
                    centro: 'Danieli',
                    valor: 26639
                },
                {
                    centro: 'Pomini',
                    valor: 13518
                },
                {
                    centro: 'SMS',
                    valor: 51058
                }
            ]
        },
        stocks: [
            {
                centro: 'Danieli',
                valor: 100
            },
            {
                centro: 'Pomini',
                valor: 100
            },
            {
                centro: 'SMS',
                valor: 100
            },
            {
                centro: 'Total',
                valor: 300
            }
        ],
        productividad: 138
    },
    {
        key: '1',
        fecha: '20/04/2023',
        produccion: {
            horas: [
                {
                    centro: RESOURCES.CC1,
                    valor: 308
                },
                {
                    centro: RESOURCES.CC2,
                    valor: 309
                },
            ],
            datosProduccion: [
                {
                    centro: 'Total',
                    colada: 1,
                    valor: 46395
                },
                {
                    centro: 'Danieli',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'Pomini',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'Venta',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'SMS',
                    colada: 1,
                    valor: 46395
                },
                {
                    centro: 'Total',
                    colada: 2,
                    valor: 43638
                },
                {
                    centro: 'Danieli',
                    colada: 2,
                    valor: 30615
                },
                {
                    centro: 'Pomini',
                    colada: 2,
                    valor: 13023
                },
                {
                    centro: 'SMS',
                    colada: 2,
                    valor: 0
                }
            ],
        },
        consumo: {
            horas: [
                {
                    centro: 'Danieli',
                    valor: 344
                },
                {
                    centro: 'Pomini',
                    valor: 192
                },
                {
                    centro: 'SMS',
                    valor: 417
                }
            ],
            datosConsumo: [
                {
                    centro: 'Danieli',
                    valor: 26639
                },
                {
                    centro: 'Pomini',
                    valor: 13518
                },
                {
                    centro: 'SMS',
                    valor: 51058
                }
            ]
        },
        stocks: [
            {
                centro: 'Danieli',
                valor: 100
            },
            {
                centro: 'Pomini',
                valor: 100
            },
            {
                centro: 'SMS',
                valor: 100
            },
            {
                centro: 'Total',
                valor: 300
            }
        ],
        productividad: 138
    },
    {
        key: '1',
        fecha: '20/04/2023',
        produccion: {
            horas: [
                {
                    centro: RESOURCES.CC1,
                    valor: 308
                },
                {
                    centro: RESOURCES.CC2,
                    valor: 309
                },
            ],
            datosProduccion: [
                {
                    centro: 'Total',
                    colada: 1,
                    valor: 46395
                },
                {
                    centro: 'Danieli',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'Pomini',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'Venta',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'SMS',
                    colada: 1,
                    valor: 46395
                },
                {
                    centro: 'Total',
                    colada: 2,
                    valor: 43638
                },
                {
                    centro: 'Danieli',
                    colada: 2,
                    valor: 30615
                },
                {
                    centro: 'Pomini',
                    colada: 2,
                    valor: 13023
                },
                {
                    centro: 'SMS',
                    colada: 2,
                    valor: 0
                }
            ],
        },
        consumo: {
            horas: [
                {
                    centro: 'Danieli',
                    valor: 344
                },
                {
                    centro: 'Pomini',
                    valor: 192
                },
                {
                    centro: 'SMS',
                    valor: 417
                }
            ],
            datosConsumo: [
                {
                    centro: 'Danieli',
                    valor: 26639
                },
                {
                    centro: 'Pomini',
                    valor: 13518
                },
                {
                    centro: 'SMS',
                    valor: 51058
                }
            ]
        },
        stocks: [
            {
                centro: 'Danieli',
                valor: 100
            },
            {
                centro: 'Pomini',
                valor: 100
            },
            {
                centro: 'SMS',
                valor: 100
            },
            {
                centro: 'Total',
                valor: 300
            }
        ],
        productividad: 138
    },
    {
        key: '1',
        fecha: '20/04/2023',
        produccion: {
            horas: [
                {
                    centro: RESOURCES.CC1,
                    valor: 308
                },
                {
                    centro: RESOURCES.CC2,
                    valor: 309
                },
            ],
            datosProduccion: [
                {
                    centro: 'Total',
                    colada: 1,
                    valor: 46395
                },
                {
                    centro: 'Danieli',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'Pomini',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'Venta',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'SMS',
                    colada: 1,
                    valor: 46395
                },
                {
                    centro: 'Total',
                    colada: 2,
                    valor: 43638
                },
                {
                    centro: 'Danieli',
                    colada: 2,
                    valor: 30615
                },
                {
                    centro: 'Pomini',
                    colada: 2,
                    valor: 13023
                },
                {
                    centro: 'SMS',
                    colada: 2,
                    valor: 0
                }
            ],
        },
        consumo: {
            horas: [
                {
                    centro: 'Danieli',
                    valor: 344
                },
                {
                    centro: 'Pomini',
                    valor: 192
                },
                {
                    centro: 'SMS',
                    valor: 417
                }
            ],
            datosConsumo: [
                {
                    centro: 'Danieli',
                    valor: 26639
                },
                {
                    centro: 'Pomini',
                    valor: 13518
                },
                {
                    centro: 'SMS',
                    valor: 51058
                }
            ]
        },
        stocks: [
            {
                centro: 'Danieli',
                valor: 100
            },
            {
                centro: 'Pomini',
                valor: 100
            },
            {
                centro: 'SMS',
                valor: 100
            },
            {
                centro: 'Total',
                valor: 300
            }
        ],
        productividad: 138
    },
    {
        key: '1',
        fecha: '20/04/2023',
        produccion: {
            horas: [
                {
                    centro: RESOURCES.CC1,
                    valor: 308
                },
                {
                    centro: RESOURCES.CC2,
                    valor: 309
                },
            ],
            datosProduccion: [
                {
                    centro: 'Total',
                    colada: 1,
                    valor: 46395
                },
                {
                    centro: 'Danieli',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'Pomini',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'Venta',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'SMS',
                    colada: 1,
                    valor: 46395
                },
                {
                    centro: 'Total',
                    colada: 2,
                    valor: 43638
                },
                {
                    centro: 'Danieli',
                    colada: 2,
                    valor: 30615
                },
                {
                    centro: 'Pomini',
                    colada: 2,
                    valor: 13023
                },
                {
                    centro: 'SMS',
                    colada: 2,
                    valor: 0
                }
            ],
        },
        consumo: {
            horas: [
                {
                    centro: 'Danieli',
                    valor: 344
                },
                {
                    centro: 'Pomini',
                    valor: 192
                },
                {
                    centro: 'SMS',
                    valor: 417
                }
            ],
            datosConsumo: [
                {
                    centro: 'Danieli',
                    valor: 26639
                },
                {
                    centro: 'Pomini',
                    valor: 13518
                },
                {
                    centro: 'SMS',
                    valor: 51058
                }
            ]
        },
        stocks: [
            {
                centro: 'Danieli',
                valor: 100
            },
            {
                centro: 'Pomini',
                valor: 100
            },
            {
                centro: 'SMS',
                valor: 100
            },
            {
                centro: 'Total',
                valor: 300
            }
        ],
        productividad: 138
    },
    {
        key: '1',
        fecha: '20/04/2023',
        produccion: {
            horas: [
                {
                    centro: RESOURCES.CC1,
                    valor: 308
                },
                {
                    centro: RESOURCES.CC2,
                    valor: 309
                },
            ],
            datosProduccion: [
                {
                    centro: 'Total',
                    colada: 1,
                    valor: 46395
                },
                {
                    centro: 'Danieli',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'Pomini',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'Venta',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'SMS',
                    colada: 1,
                    valor: 46395
                },
                {
                    centro: 'Total',
                    colada: 2,
                    valor: 43638
                },
                {
                    centro: 'Danieli',
                    colada: 2,
                    valor: 30615
                },
                {
                    centro: 'Pomini',
                    colada: 2,
                    valor: 13023
                },
                {
                    centro: 'SMS',
                    colada: 2,
                    valor: 0
                }
            ],
        },
        consumo: {
            horas: [
                {
                    centro: 'Danieli',
                    valor: 344
                },
                {
                    centro: 'Pomini',
                    valor: 192
                },
                {
                    centro: 'SMS',
                    valor: 417
                }
            ],
            datosConsumo: [
                {
                    centro: 'Danieli',
                    valor: 26639
                },
                {
                    centro: 'Pomini',
                    valor: 13518
                },
                {
                    centro: 'SMS',
                    valor: 51058
                }
            ]
        },
        stocks: [
            {
                centro: 'Danieli',
                valor: 100
            },
            {
                centro: 'Pomini',
                valor: 100
            },
            {
                centro: 'SMS',
                valor: 100
            },
            {
                centro: 'Total',
                valor: 300
            }
        ],
        productividad: 138
    },
    {
        key: '1',
        fecha: '20/04/2023',
        produccion: {
            horas: [
                {
                    centro: RESOURCES.CC1,
                    valor: 308
                },
                {
                    centro: RESOURCES.CC2,
                    valor: 309
                },
            ],
            datosProduccion: [
                {
                    centro: 'Total',
                    colada: 1,
                    valor: 46395
                },
                {
                    centro: 'Danieli',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'Pomini',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'Venta',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'SMS',
                    colada: 1,
                    valor: 46395
                },
                {
                    centro: 'Total',
                    colada: 2,
                    valor: 43638
                },
                {
                    centro: 'Danieli',
                    colada: 2,
                    valor: 30615
                },
                {
                    centro: 'Pomini',
                    colada: 2,
                    valor: 13023
                },
                {
                    centro: 'SMS',
                    colada: 2,
                    valor: 0
                }
            ],
        },
        consumo: {
            horas: [
                {
                    centro: 'Danieli',
                    valor: 344
                },
                {
                    centro: 'Pomini',
                    valor: 192
                },
                {
                    centro: 'SMS',
                    valor: 417
                }
            ],
            datosConsumo: [
                {
                    centro: 'Danieli',
                    valor: 26639
                },
                {
                    centro: 'Pomini',
                    valor: 13518
                },
                {
                    centro: 'SMS',
                    valor: 51058
                }
            ]
        },
        stocks: [
            {
                centro: 'Danieli',
                valor: 100
            },
            {
                centro: 'Pomini',
                valor: 100
            },
            {
                centro: 'SMS',
                valor: 100
            },
            {
                centro: 'Total',
                valor: 300
            }
        ],
        productividad: 138
    },
    {
        key: '1',
        fecha: '20/04/2023',
        produccion: {
            horas: [
                {
                    centro: RESOURCES.CC1,
                    valor: 308
                },
                {
                    centro: RESOURCES.CC2,
                    valor: 309
                },
            ],
            datosProduccion: [
                {
                    centro: 'Total',
                    colada: 1,
                    valor: 46395
                },
                {
                    centro: 'Danieli',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'Pomini',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'Venta',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'SMS',
                    colada: 1,
                    valor: 46395
                },
                {
                    centro: 'Total',
                    colada: 2,
                    valor: 43638
                },
                {
                    centro: 'Danieli',
                    colada: 2,
                    valor: 30615
                },
                {
                    centro: 'Pomini',
                    colada: 2,
                    valor: 13023
                },
                {
                    centro: 'SMS',
                    colada: 2,
                    valor: 0
                }
            ],
        },
        consumo: {
            horas: [
                {
                    centro: 'Danieli',
                    valor: 344
                },
                {
                    centro: 'Pomini',
                    valor: 192
                },
                {
                    centro: 'SMS',
                    valor: 417
                }
            ],
            datosConsumo: [
                {
                    centro: 'Danieli',
                    valor: 26639
                },
                {
                    centro: 'Pomini',
                    valor: 13518
                },
                {
                    centro: 'SMS',
                    valor: 51058
                }
            ]
        },
        stocks: [
            {
                centro: 'Danieli',
                valor: 100
            },
            {
                centro: 'Pomini',
                valor: 100
            },
            {
                centro: 'SMS',
                valor: 100
            },
            {
                centro: 'Total',
                valor: 300
            }
        ],
        productividad: 138
    },
    {
        key: '1',
        fecha: '20/04/2023',
        produccion: {
            horas: [
                {
                    centro: RESOURCES.CC1,
                    valor: 308
                },
                {
                    centro: RESOURCES.CC2,
                    valor: 309
                },
            ],
            datosProduccion: [
                {
                    centro: 'Total',
                    colada: 1,
                    valor: 46395
                },
                {
                    centro: 'Danieli',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'Pomini',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'Venta',
                    colada: 1,
                    valor: 0
                },
                {
                    centro: 'SMS',
                    colada: 1,
                    valor: 46395
                },
                {
                    centro: 'Total',
                    colada: 2,
                    valor: 43638
                },
                {
                    centro: 'Danieli',
                    colada: 2,
                    valor: 30615
                },
                {
                    centro: 'Pomini',
                    colada: 2,
                    valor: 13023
                },
                {
                    centro: 'SMS',
                    colada: 2,
                    valor: 0
                }
            ],
        },
        consumo: {
            horas: [
                {
                    centro: 'Danieli',
                    valor: 344
                },
                {
                    centro: 'Pomini',
                    valor: 192
                },
                {
                    centro: 'SMS',
                    valor: 417
                }
            ],
            datosConsumo: [
                {
                    centro: 'Danieli',
                    valor: 26639
                },
                {
                    centro: 'Pomini',
                    valor: 13518
                },
                {
                    centro: 'SMS',
                    valor: 51058
                }
            ]
        },
        stocks: [
            {
                centro: 'Danieli',
                valor: 100
            },
            {
                centro: 'Pomini',
                valor: 100
            },
            {
                centro: 'SMS',
                valor: 100
            },
            {
                centro: 'Total',
                valor: 300
            }
        ],
        productividad: 138
    }
];


const TablaCiclos = () => {
    // const [dataProduccion, setDataProduccion] = useState([]);

    // useEffect(() => {
    //     const a = async () => {
    //         await axios.get('test.json')
    //             .then(response => {
    //                 console.log(response.data)
    //                 setDataProduccion(response.data)
    //             })
    //             .catch(err => console.log(err));
    //     }
    //     a();
    // }, [])
    return (
        <div>
            <Row>
                <Col span={20}>
                </Col>
                <Col span={4}>
                    <DatePickerFilter />
                </Col>
            </Row>
            <Table size='small' bordered sticky dataSource={dataProduccion} columns={columnsProduccion} scroll={{ x: 1800 }} />
        </div>
    )
}

export default TablaCiclos;