import { UserOutlined } from "@ant-design/icons";
import { Avatar, List, Popover, Space } from "antd";
import Logout from "../../Logout";
import { AvatarStyle, ListItemStyle } from "./style";

function UserMenuOptions() {

  const items = [
    {
      label: <Logout>Logout</Logout>,
      key: "logout",
      hover: 2,
    },
  ];

  const list = (
    <List
      dataSource={items}
      renderItem={(item) => <ListItemStyle hover={item.hover}>{item.label}</ListItemStyle>}
    ></List>
  );

  return (
    <>
      <Space>
        <Popover placement="bottomLeft" content={list} trigger={["click"]} showArrow={false}>
          <AvatarStyle>
            <Avatar icon={<UserOutlined />}></Avatar>
          </AvatarStyle>
        </Popover>
      </Space>
    </>
  );
}

export default UserMenuOptions;
