import { AuthService } from "aquiles-security-library";
import { useAtom } from 'jotai';
import { Navigate } from "react-router";
import { COOKIE, LOGIN_TYPE, SETTINGS } from "../constants";
import { loginAtom } from "../store/atoms";

const RequireAuth = ({ children }: any) => {
    const [login, setLogin] = useAtom(loginAtom);
    const loginType = localStorage.getItem(COOKIE.LOGIN_TYPE);

    let reLogin = false;

    if (loginType == LOGIN_TYPE.MICROSOFT) reLogin = MicrosoftAuth();
    else if (loginType == LOGIN_TYPE.AQUILES_AUTH) reLogin = AquilesAuth();
    else reLogin = true;
    
    // Se ha hecho logout
    if (login == 'false' || reLogin) {
        if (loginType == LOGIN_TYPE.AQUILES_AUTH) 
        {
            const auth = new AuthService();
            let tokenName = SETTINGS.tokenName;
            auth.logout(tokenName);
        }

        localStorage.clear();
        return <Navigate to="/" />;
    }

    return children;
};

const MicrosoftAuth = () => {
    // Revisar caducidad token.
    const token = localStorage.getItem(COOKIE.TOKEN);
        
    // if (token está caducado o no existe y refresh token existe...)
    // obtener otro token con el refresh token
    const refreshToken = localStorage.getItem(COOKIE.REFRESH_TOKEN);

    // Si no hay token y no se puede obtener uno, hacer LOGIN. 
    return false;
}

const AquilesAuth = () => {
    const auth = new AuthService()
    let tokenName = SETTINGS.tokenName;
    if (!auth.isAuthenticated(tokenName)) return true;
    else return false;
}

export default RequireAuth;