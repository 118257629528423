import { Pie } from '@ant-design/plots';

const ChartPie = () => {
  const data = [
    {
      type: '1',
      value: 27,
    },
    {
      type: '2',
      value: 25,
    },
    {
      type: '3',
      value: 18,
    },
    {
      type: '4',
      value: 15,
    },
    {
      type: '5',
      value: 10,
    },
    {
      type: '6',
      value: 5,
    },
  ];
  const config = {
    appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'type',
    radius: 0.9,
    label: {
      type: 'inner',
      offset: '-30%',
      style: {
        fontSize: 14,
        textAlign: 'center',
      },
    },
    interactions: [
      {
        type: 'element-active',
      },
    ],
  };
  return <Pie {...config} />;
};


export default ChartPie;